import { alertAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(alertAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    padding: `1rem`,
    boxShadow: `card`,
  },
  icon: {
    marginRight: `0.75rem`,
  },
  title: {
    fontSize: `lg`,
    fontWeight: 500,
    pr: `0.5rem`,
  },
  description: {
    fontSize: `xs`,
    lineHeight: `1.125rem`,
    paddingTop: `0.25rem`,
    paddingRight: `1rem`,
  },
});

const darkSky = definePartsStyle({
  container: {
    background: `sky.800`,
    color: `white`,
  },
});

const lightSky = definePartsStyle({
  container: {
    background: `sky.100`,
    borderWidth: `1px`,
    borderColor: `sky.600`,
    color: `grey.900`,
  },
  icon: {
    color: `sky.600`,
  },
});

const condensedLightSky = definePartsStyle({
  container: {
    alignItems: `center`,
    background: `sky.100`,
  },
  title: {
    color: `grey.900`,
    fontSize: `xs`,
    fontWeight: 400,
  },
});

const grey = definePartsStyle({
  container: {
    background: `grey.25`,
    borderColor: `grey.800`,
    borderRadius: `md`,
    borderWidth: 1,
  },
});

const Alert = defineMultiStyleConfig({
  baseStyle,
  variants: { condensedLightSky, darkSky, grey, lightSky },
});

export default Alert;
