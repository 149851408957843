import { useTokenExchangeMutation } from "@/graphql";
import { handleGqlError } from "@/modules/Error";
import { useCustomToast } from "@/modules/Toast";
import { setAuth } from "@/state/auth";
import i18next from "i18next";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

const DEFAULT_ERROR_MESSAGE = i18next.t(`something_went_wrong_try_again_later`);

export const useTokenExchange = ({
  onError,
  onSuccess,
}: {
  onError?: () => void;
  onSuccess?: () => void;
} = {}) => {
  const [fetching, setFetching] = useState(false);
  const [{ data, error }, tokenExchange] = useTokenExchangeMutation();
  const { errorToast } = useCustomToast();
  const dispatch = useDispatch();

  const exchangeToken = useCallback(
    async (identityToken: string) => {
      setFetching(true);
      const { data, error } = await tokenExchange({ identityToken });

      if (data?.token?.session?.token) {
        const { currentUser: _user, ...session } = data.token.session;
        dispatch(setAuth(session));
        onSuccess?.();
      } else {
        const gqlErrorMessage = error
          ? handleGqlError(error)
          : DEFAULT_ERROR_MESSAGE;
        errorToast(gqlErrorMessage, { id: `useTokenExchange` });
        onError?.();
      }
      setFetching(false);
    },
    [tokenExchange, dispatch, onSuccess, errorToast, onError],
  );

  return { fetching, data, error, exchangeToken };
};
