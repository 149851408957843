import { useUrqlClient } from "@/modules/Urql";
import { ReactNode } from "react";
import { Provider as GraphqlProvider } from "urql";

interface UrqlProviderProps {
  children: ReactNode;
}

export default function UrqlProvider({ children }: UrqlProviderProps) {
  const client = useUrqlClient();
  return <GraphqlProvider value={client}>{children}</GraphqlProvider>;
}
