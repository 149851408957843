import { NumberInput, NumberInputField } from "@chakra-ui/react";

import currency from "currency.js";
import { ChangeEvent, useState } from "react";
import { FieldError } from "react-hook-form";

type MoneyInputType = {
  readonly name: string;
  readonly isDisabled?: boolean;
  readonly value: string;
  readonly placeholder?: string;
  readonly onChange: (value: string) => void;
  readonly onBlur: () => void;
  readonly error?: FieldError;
};

function MoneyInput({
  name,
  isDisabled = false,
  value,
  placeholder,
  onChange,
  onBlur,
  error,
  ...props
}: MoneyInputType) {
  const [_value, setValue] = useState<string>(value);
  const maxInputValue = 1e15;

  const _fromValue = (value: string): string => {
    // NumberInput crashes on null
    if (value === null || value === ``) return ``;

    switch (value.lastIndexOf(`.`)) {
      case -1:
        return currency(value, { precision: 0 }).format();
      case value.length - 1:
        return `$${value}`;
      case value.length - 2:
        return currency(value, { precision: 1 }).format();
      default:
        return currency(value, { precision: 2 }).format();
    }
  };

  const onInputChanged = (value: string) => {
    setValue(value);
    onChange?.(value.toString());
  };

  const formatNumber = (number: string) =>
    number.replace(/\D/g, ``).replace(/\B(?=(\d{3})+(?!\d))/g, `,`);

  const onFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const cursorPosition = event.target.selectionStart;

    if (inputValue === ``) {
      event.target.setSelectionRange(cursorPosition, cursorPosition);
      return event;
    }

    const originalLength = inputValue.length;
    const decimalPosition = inputValue.indexOf(`.`);

    // does value have a decimal
    if (decimalPosition >= 0) {
      const head = formatNumber(inputValue.substring(0, decimalPosition));
      const tail = formatNumber(
        inputValue.substring(decimalPosition),
      ).substring(0, 2);
      // save formatted value back into input
      event.target.value = `$${head}.${tail}`; // eslint-disable-line
    } else {
      event.target.value = `$${formatNumber(inputValue)}`; // eslint-disable-line
    }

    // persist the cursor position regardless if non-numerical chars were added for formatting
    const updatedLength = event.target.value.length;
    const updatedCursorPosition =
      updatedLength - originalLength + (cursorPosition || 0);

    event.target.setSelectionRange(
      updatedCursorPosition,
      updatedCursorPosition,
    );

    return event;
  };

  const val = _fromValue(_value);

  return (
    <NumberInput
      flex="1"
      errorBorderColor={error && `red.500`}
      name={name}
      isDisabled={isDisabled}
      min={0}
      pattern="[0-9,\.]*(.[0-9,\.]+)?"
      onChange={onInputChanged}
      value={val}
      max={maxInputValue}
      onBlur={onBlur}
      {...props}
    >
      <NumberInputField placeholder={placeholder} onChange={onFieldChange} />
    </NumberInput>
  );
}

export default MoneyInput;
