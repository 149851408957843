import { useToast, UseToastOptions } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { ToastDuration } from "@/modules/Toast";

const useCustomToast = () => {
  const toast = useToast();
  const { t } = useTranslation();

  const baseToast = (opts?: UseToastOptions) =>
    !toast.isActive(opts?.id || ``)
      ? toast({
          duration: ToastDuration.Short,
          isClosable: true,
          ...opts,
        })
      : null;

  const successToast = (title?: string, opts?: UseToastOptions) =>
    baseToast({
      title: title ?? t(`action_performed_successfully`),
      status: `success`,
      duration: ToastDuration.Short,
      variant: `darkSky`,
      ...opts,
    });

  const successToastLight = (title?: string, opts?: UseToastOptions) =>
    successToast(title, {
      variant: `lightSky`,
      ...opts,
    });

  const infoToast = (title: string, opts?: UseToastOptions) =>
    baseToast({
      title,
      status: `info`,
      duration: ToastDuration.Short,
      variant: `lightSky`,
      ...opts,
    });

  const infoToastCondensed = (title: string, opts?: UseToastOptions) =>
    infoToast(title, {
      variant: `condensedLightSky`,
      isClosable: false,
      ...opts,
    });

  const errorToast = (title?: string | null, opts?: UseToastOptions) =>
    baseToast({
      title: title ?? t(`something_went_wrong`),
      status: `error`,
      duration: ToastDuration.Short,
      ...opts,
    });

  return {
    errorToast,
    infoToast,
    infoToastCondensed,
    successToast,
    successToastLight,
  };
};

export default useCustomToast;
