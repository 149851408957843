/* eslint-disable functional/immutable-data */
import { Input as ChakraInput, forwardRef } from "@chakra-ui/react";

import { ChangeEvent, FocusEvent } from "react";
import { UseComboboxGetInputProps, InputProps } from "@/modules/Form";

interface ComboboxInputProps extends InputProps {
  readonly getInputProps: UseComboboxGetInputProps;
}

const ComboboxInput = forwardRef<ComboboxInputProps, `input`>(
  ({ getInputProps, ...inputProps }: ComboboxInputProps, ref) => {
    const {
      onChange: inputNativeOnChange,
      onBlur: inputNativeOnBlur,
      ref: _inputNativeRef,
      ...newInputProps
    } = inputProps;
    const {
      onChange: inputOnChange,
      onBlur: inputOnBlur,
      ref: _inputRef,
      value: _value,
      ...comboboxInputProps
    } = getInputProps({
      ref,
    });

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
      inputOnChange(event);
      inputNativeOnChange(event);
    };

    const onBlur = (event: FocusEvent<HTMLInputElement>) => {
      inputOnBlur(event);
      inputNativeOnBlur(event);
    };

    return (
      <ChakraInput
        {...newInputProps}
        {...comboboxInputProps}
        onChange={onChange}
        onBlur={onBlur}
        ref={ref}
      />
    );
  },
);

export default ComboboxInput;
