const breakpoints = {
  sm: `30em`,
  md: `48em`,
  lg: `62em`,
  xl: `80em`,
  "2xl": `88em`,
  "3xl": `96em`,
  "4xl": `104em`,
  "5xl": `112em`,
};

export default breakpoints;
