import { inputAnatomy as parts } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  defineStyleConfig,
  Input as InputComponent,
} from "@chakra-ui/react";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseField = {
  borderColor: `grey.600`,
  borderWidth: 1,
  bg: `white`,
  borderRadius: `md`,
  autoComplete: `off`,
  _disabled: {
    pointerEvents: `none`,
    borderColor: `grey.200`,
    _placeholder: {
      color: `grey.200`,
    },
  },
  _hover: {
    borderColor: `grey.200`,
  },
  _placeholder: {
    color: `grey.600`,
  },
  _focus: {
    boxShadow: `focus`,
    borderColor: `grey.600`,
  },
  _invalid: {
    borderColor: `red.500`,
    borderWidth: `2px`,
  },
};

const Input = defineStyleConfig(
  defineMultiStyleConfig({
    defaultProps: {
      ...InputComponent.defaultProps,
      variant: `base`,
    },
    sizes: {
      xl: {
        field: {
          fontSize: `1.375rem`,
          lineHeight: `1.65rem`,
          fontWeight: 500,
        },
      },
    },
    variants: {
      base: {
        field: baseField,
      },
      borderless: {
        field: {
          border: `none`,
          padding: 1,
          marginX: -1,
          ...baseField,
        },
      },
    },
  }),
);

export default Input;
