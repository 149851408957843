import { ToastDuration, useCustomToast } from "@/modules/Toast";
import { Button, Text, VStack } from "@chakra-ui/react";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

const API_PARAM = `api`;
const IS_PRODUCTION =
  process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT === `production`;
const API_GQL_PATH = `graphql/back-office`;

function isValidUrl(url: string) {
  try {
    return Boolean(new URL(url));
  } catch (e) {
    return false;
  }
}

function getGqlUrl(apiParam: string) {
  return apiParam.endsWith(API_GQL_PATH)
    ? apiParam
    : `${apiParam.endsWith(`/`) ? apiParam : `${apiParam}/`}${API_GQL_PATH}`;
}

function getApiUrl(onPreviewSetup: (url: string) => void) {
  if (IS_PRODUCTION || typeof window === `undefined` || !localStorage) {
    return process.env.NEXT_PUBLIC_API_URL;
  }

  const params = new URLSearchParams(window.location.search);
  const newApiParam = params.get(API_PARAM) || ``;
  const prevApiParam = localStorage.getItem(API_PARAM) || ``;

  const apiParam = newApiParam || prevApiParam;

  if (!isValidUrl(apiParam)) {
    return process.env.NEXT_PUBLIC_API_URL;
  }

  const apiUrl = getGqlUrl(apiParam);

  if (apiUrl === process.env.NEXT_PUBLIC_API_URL) {
    localStorage.removeItem(API_PARAM);
    return process.env.NEXT_PUBLIC_API_URL;
  }

  const prevApiUrl = getGqlUrl(prevApiParam);

  if (apiUrl !== prevApiUrl) {
    localStorage.setItem(API_PARAM, apiParam);
  }

  onPreviewSetup(apiParam);
  return apiUrl;
}

function resetApiUrl() {
  localStorage.removeItem(API_PARAM);
  const url = new URL(window.location.href);
  url.searchParams.delete(API_PARAM);
  window.location.replace(url);
}

function ServerPreviewToast({ url }: { url: string }) {
  const { t } = useTranslation();
  return (
    <VStack gap={4} pt={2}>
      <Text>{url}</Text>
      <Button variant="solid" size="md" onClick={resetApiUrl}>
        {t(`use_default`)}
      </Button>
    </VStack>
  );
}

export function useServerPreview() {
  const { t } = useTranslation();
  const { infoToast } = useCustomToast();

  const showServerPreviewInfo = useCallback(
    (url: string) =>
      infoToast(t(`server_preview_setup`), {
        id: url,
        description: <ServerPreviewToast url={url} />,
        duration: ToastDuration.Long,
      }),
    [infoToast, t],
  );

  return useMemo(
    () => getApiUrl(showServerPreviewInfo),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
