import translation from "./en.json";
import users from "./users";
import institutions from "./institutions";

type Locale = typeof translation;

type LocaleKey = "en";

export type TranslationKey = keyof typeof translation;

export type Resources = {
  readonly institutions: typeof institutions;
  readonly translation: Locale;
  readonly users: typeof users;
};

export const locales: Record<LocaleKey, Resources> = {
  en: { institutions, translation, users },
};
