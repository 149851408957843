import { Input, InputGroup } from "@chakra-ui/react";
import { ForwardedRef, forwardRef } from "react";
import { InputProps } from "./Input";
import GenericInput from "./GenericInput";

interface DateInputProps extends InputProps {
  minDate?: string;
  maxDate?: string;
}

const DateInput = forwardRef<typeof Input, DateInputProps>(
  (
    { minDate, maxDate, type: _, label, ...inputProps }: DateInputProps,
    ref: ForwardedRef<typeof Input>,
  ) => (
    <InputGroup w="full">
      <GenericInput
        type="date"
        min={minDate}
        max={maxDate}
        label={label}
        {...inputProps}
        ref={ref}
      />
    </InputGroup>
  ),
);

export default DateInput;
