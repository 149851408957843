import { ForwardedRef, forwardRef } from "react";
import {
  SelectFieldProps as ChakraSelectFieldProps,
  Select,
} from "@chakra-ui/react";
import { UseFormRegisterReturn } from "react-hook-form";
import { CaretDown } from "@phosphor-icons/react";
import InputWrapper, { InputWrapperProps } from "./InputWrapper";

type Props = InputWrapperProps &
  ChakraSelectFieldProps &
  Omit<UseFormRegisterReturn, "onBlur">;

interface SelectInputProps extends Props {
  options: (
    | {
        label: string;
        value: string;
      }
    | string
  )[];
}

const SelectInput = forwardRef<typeof Select, SelectInputProps>(
  (
    { name, options, ...selectProps }: SelectInputProps,
    ref: ForwardedRef<typeof Select>,
  ) => (
    <InputWrapper name={name} {...selectProps}>
      <Select
        id={name}
        name={name}
        {...selectProps}
        ref={ref}
        icon={<CaretDown />}
      >
        {options.map((option) => {
          if (typeof option === `object`) {
            const { value, label } = option;
            return (
              <option key={value} value={value}>
                {label}
              </option>
            );
          }
          return (
            <option key={option} value={option}>
              {option}
            </option>
          );
        })}
      </Select>
    </InputWrapper>
  ),
);

export default SelectInput;
