import { useRouter } from "next/router";
import { ReactNode, useMemo } from "react";

import { HiiveLogo } from "@/modules/Assets";
import {
  useBackOfficeConfiguration,
  useHiive50,
  useTEXASEnabled,
} from "@/modules/LaunchDarkly";
import { LinkTarget } from "@/modules/Navigation";
import { ProfileMenu } from "@/modules/ProfileMenu";
import { HStack } from "@chakra-ui/react";
import {
  BookBookmark,
  Buildings,
  ChartLine,
  GitBranch,
  Handshake,
  Stack,
  Suitcase,
  Tag,
  User,
  UsersThree,
} from "@phosphor-icons/react";

import { useSession } from "@/modules/Session";
import i18next from "i18next";
import { ROUTES } from "@/constants";
import { TopMenuItem } from "./TopMenuItem";

type NavLinkType = {
  href: string;
  params?: string;
  icon: ReactNode;
  label: string;
};

const HIIVE50_LINK: NavLinkType = {
  href: `/hiive50`,
  icon: <ChartLine size={24} />,
  label: `Hiive50`,
};

const WORKFLOW_TEMPLATES_LINK: NavLinkType = {
  href: `/workflow-templates`,
  icon: <Stack size={24} />,
  label: `Templates`,
};

const WORKFLOWS_LINK: NavLinkType = {
  href: `/workflows`,
  icon: <GitBranch size={24} />,
  label: `Workflows`,
};

export default function TopMenu() {
  const { isAuthenticated } = useSession();
  const { pathname = `` } = useRouter();
  const backOfficeConfigurationEnabled = useBackOfficeConfiguration();

  const hiive50Enabled = useHiive50();
  const TEXASEnabled = useTEXASEnabled();

  const shouldEnforceMfa =
    backOfficeConfigurationEnabled && pathname === ROUTES.SETUP_MFA;

  const links = useMemo(() => {
    const navLinks = shouldEnforceMfa
      ? []
      : [
          {
            href: `/companies`,
            icon: <Buildings size={24} />,
            label: `Companies`,
          },
          {
            href: `/transactions`,
            icon: <Handshake size={24} />,
            label: `Transactions`,
          },
          {
            href: `/users`,
            icon: <User size={24} />,
            label: `Users`,
          },
          {
            href: `/institutions`,
            icon: <UsersThree size={24} />,
            label: `Institutions`,
          },
          {
            href: `/listings`,
            icon: <Suitcase size={24} />,
            label: `Listings`,
          },
          {
            href: `/bids`,
            icon: <Suitcase size={24} />,
            label: i18next.t(`bids`),
          },
          {
            href: `/standing-bids`,
            icon: <Suitcase size={24} />,
            label: i18next.t(`standing_bids`),
          },
          {
            href: `/broker-orders`,
            icon: <BookBookmark size={24} />,
            label: `Broker Orders`,
          },
          {
            href: `/fee-discounts`,
            params: `?active=true`,
            icon: <Tag size={24} />,
            label: `Fee Discounts`,
          },
          ...(hiive50Enabled ? [HIIVE50_LINK] : []),
          ...(TEXASEnabled ? [WORKFLOW_TEMPLATES_LINK] : []),
          ...(TEXASEnabled ? [WORKFLOWS_LINK] : []),
        ];

    return navLinks.map((link) => ({
      ...link,
      disabled: !isAuthenticated,
      isActive: pathname.startsWith(link.href),
    }));
  }, [
    shouldEnforceMfa,
    hiive50Enabled,
    TEXASEnabled,
    isAuthenticated,
    pathname,
  ]);

  return (
    <HStack
      as="nav"
      backgroundColor="grey.800"
      px={4}
      py={2}
      justifyContent="space-between"
    >
      <HStack gap={2}>
        {links.map(({ href, params = ``, ...link }) => (
          <TopMenuItem key={href} href={`${href}${params}`} {...link} />
        ))}
      </HStack>
      <HStack gap={2}>
        <TopMenuItem
          href={process.env.NEXT_PUBLIC_HIIVE_URL}
          icon={<HiiveLogo />}
          label="Hiive Platform"
          target={LinkTarget.BLANK}
        />
        <ProfileMenu />
      </HStack>
    </HStack>
  );
}
