export type FeatureFlags = {
  "hiive-preqin-marketplace-toggle": boolean;
  "issuer.bo_frontegg_auth": string[];
  "marketplace.hiive_50": boolean;
  "marketplace.multiple_holdings": boolean;
  "issuer.fee_discount.archive_discount": boolean;
  "issuer.auth_system.back_office_configuration": boolean;
  "execution.seller_external_account": boolean;
  "issuer.fee_discount.transaction_one_time_application": boolean;
  "execution.transaction_execution_automation_system": boolean;
  "issuer.fee_discount.preview": boolean;
  "issuer.fee_discount.auto_naming": boolean;
  "execution.multi_entity_experience": boolean;
  "issuer.managed_markets.enable": boolean;
  "marketplace.suitability_upfront": boolean;
  "issuer.company_approved_buyers.enable": boolean;
  "marketplace.trusted-contact-person": boolean;
  "marketplace.suitability_v3": boolean;
  "marketplace.spv_to_fund": boolean;
  "issuer.auth_system.back_office_session": boolean;
};

export enum FeatureFlagKeys {
  ISSUER_AUTH_SYSTEM_BACK_OFFICE_LOGIN = `issuer.auth_system.back_office_login`,
}

const featureFlags: FeatureFlags = {
  "hiive-preqin-marketplace-toggle": false,
  "issuer.bo_frontegg_auth": [],
  "marketplace.hiive_50": false,
  "marketplace.multiple_holdings": false,
  "issuer.fee_discount.archive_discount": false,
  "issuer.auth_system.back_office_configuration": false,
  "execution.seller_external_account": false,
  "issuer.fee_discount.transaction_one_time_application": false,
  "execution.transaction_execution_automation_system": false,
  "issuer.fee_discount.preview": false,
  "issuer.fee_discount.auto_naming": false,
  "execution.multi_entity_experience": false,
  "issuer.managed_markets.enable": false,
  "marketplace.suitability_upfront": false,
  "issuer.company_approved_buyers.enable": false,
  "marketplace.trusted-contact-person": false,
  "marketplace.suitability_v3": false,
  "marketplace.spv_to_fund": false,
  "issuer.auth_system.back_office_session": false,
};

export default featureFlags;
