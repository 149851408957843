import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from "@chakra-ui/react";
import NextLink from "next/link";
import { ReactNode } from "react";

export enum LinkTarget {
  BLANK = `_blank`,
  SELF = `_self`,
}

interface LinkProps extends ChakraLinkProps {
  children?: ReactNode;
  href: string;
  replace?: boolean;
  scroll?: boolean;
  target?: string;
  disabled?: boolean;
}

export default function Link({
  children,
  href,
  replace,
  scroll = true,
  target,
  disabled = false,
  ...chakraProps
}: LinkProps) {
  return (
    <NextLink
      scroll={scroll}
      href={!disabled ? href : `/`}
      passHref
      replace={replace}
      target={target}
      aria-disabled={disabled}
      tabIndex={disabled ? -1 : undefined}
      style={{
        pointerEvents: disabled ? `none` : `auto`,
      }}
    >
      <ChakraLink target={target} {...chakraProps}>
        {children}
      </ChakraLink>
    </NextLink>
  );
}
