import { Error500Page } from "@/features/Error";
import { ReactNode } from "react";

export default function ErrorWrapper({
  children,
  error,
}: {
  children: ReactNode;
  error?: string;
}) {
  return error ? <Error500Page error={error} /> : children;
}
