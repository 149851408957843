import Alert from "./Alert";
import Badge from "./Badge";
import Button from "./Button";
import IconButton from "./IconButton";
import Input from "./Input";
import List from "./List";
import Menu from "./Menu";
import Modal from "./Modal";
import Tag from "./Tag";
import Tabs from "./Tabs";
import FormLabel from "./FormLabel";
import Popover from "./Popover";
import Card from "./Card";
import Textarea from "./Textarea";
import Accordion from "./Accordion";
import Checkbox from "./Checkbox";
import Status from "./Status";
import Table from "./Table";
import Drawer from "./Drawer";

const components = {
  Accordion,
  Alert,
  Badge,
  Button,
  Card,
  Drawer,
  FormLabel,
  IconButton,
  Input,
  List,
  Menu,
  Modal,
  Popover,
  Status,
  Tabs,
  Table,
  Tag,
  Textarea,
  Checkbox,
};

export default components;

export { default as Fonts } from "./Fonts";
