import { defineStyleConfig } from "@chakra-ui/react";

const Card = defineStyleConfig({
  baseStyle: {
    container: {
      bg: `white`,
      borderColor: `none`,
      borderRadius: `md`,
      boxShadow: `none`,
    },
    body: {
      p: {
        base: 4,
        md: 6,
      },
      borderBottomColor: `grey.50`,
      borderBottomWidth: `0.5px`,
      _last: {
        border: `none`,
      },
    },
    header: {
      borderBottomColor: `grey.50`,
      borderBottomWidth: `0.5px`,
      borderTopRadius: `md`,
      px: {
        base: 4,
        md: 6,
      },
      py: {
        base: 3,
        md: 5,
      },
    },
    footer: {
      px: {
        base: 4,
        md: 6,
      },
      py: {
        base: 5,
        md: 7,
      },
      borderBottomRadius: `md`,
    },
  },
  variants: {
    stepNode: {
      container: {
        bg: `white`,
        borderColor: `grey.50`,
        borderWidth: 1,
        borderRadius: `md`,
        boxShadow: `0px 2px 2px rgba(0, 0, 0, 0.15)`,
      },
    },
    flat: {
      container: {
        bg: `grey.25`,
        borderColor: `grey.50`,
        borderWidth: 1,
        borderRadius: `md`,
      },
    },
    table: {
      container: {
        borderColor: `grey.100`,
        borderWidth: `0.5px`,
        borderRadius: `none`,
      },
      header: {
        borderTopRadius: `none`,
        textTransform: `uppercase`,
        fontWeight: 500,
        px: {
          base: 2,
          md: 4,
        },
        color: `grey.600`,
        bg: `grey.25`,
        py: {
          base: 1,
          md: 2,
        },
      },
      body: {
        p: {
          base: 2,
          md: 4,
        },
      },
    },
    "table-v2": {
      container: {
        borderColor: `grey.100`,
        borderWidth: `0.5px`,
        borderRadius: `none`,
      },
      header: {
        textStyle: `heading-xs`,
        borderTopRadius: `none`,
        px: {
          base: 2,
          md: 4,
        },
        bg: `grey.25`,
        py: {
          base: 1,
          md: 2,
        },
      },
      body: {
        p: {
          base: 2,
          md: 4,
        },
      },
    },
    grey: {
      container: {
        borderColor: `grey.100`,
        borderWidth: `0.5px`,
        borderRadius: `md`,
        bg: `grey.25`,
      },
    },
    "grey-footer": {
      container: {
        bg: `white`,
        borderColor: `grey.100`,
        borderWidth: `0.5px`,
        borderRadius: `md`,
      },
      header: {
        px: 6,
        py: 4,
      },
      footer: {
        px: 6,
        py: 4,
        bg: `grey.25`,
      },
    },
    "holding-card": {
      container: {
        borderWidth: `1px`,
        borderColor: `grey.100`,
        borderStyle: `solid`,
        borderRadius: `6px`,
        mb: 4,
        p: `20px`,
      },
    },
  },
});

export default Card;
