import { SimpleTableQueryVariables } from "./modules/SimpleTable";

const DEFAULT_FILTER_BY = {
  searchText: ``,
};

export const DEFAULT_FETCH_LIMIT = 20;

export const DEFAULT_PAGE = 1;

export const ROUTES = {
  CALLBACK: `/account/login/callback`,
  DEFAULT_LOGIN: `/account/signin`,
  FRONTEGG_LOGIN: `/account/login`,
  SETUP_MFA: `/account/setup-mfa`,
  USERS: `/users`,
} as const;

export function getDefaultTableProviderVariables<
  T extends SimpleTableQueryVariables,
>(props: T) {
  return {
    page: DEFAULT_PAGE,
    first: DEFAULT_FETCH_LIMIT,
    sortBy: {},
    filterBy: DEFAULT_FILTER_BY,
    ...props,
  };
}

export const RESET_PAGINATION_PARAMS = {
  first: DEFAULT_FETCH_LIMIT,
  last: undefined,
  before: undefined,
  after: undefined,
  page: DEFAULT_PAGE,
};

export const COOKIE_KEY_LD_ANON_USER_ID = `ld_anon_user_id`;

export const GQL_OPERATION_NAMES = {
  authLogin: `authLogin`,
};
