export default {
  approve_institution: `Approve Institution`,
  confirm_approve_institution_title: `Confirm approve institution`,
  confirm_ignore_institution_title: `Confirm ignore institution`,
  confirm_remove_ignore_institution_title: `Confirm remove ignore`,
  institution_documents: `Institution Documents`,
  institution_page_head_text: `Institution | Hiive BO`,
  ignore_institution: `Ignore Institution`,
  membership_agreement_url: `Membership Agreement URL`,
  remove_ignore: `Remove Ignore`,
};
