/* eslint-disable functional/immutable-data */
import { MutableRefObject, RefObject, useEffect, useRef } from "react";
import { targetWithin } from "@/modules/DOM";

/**
 * Keeps track of whether or not the mouse is clicked down, and runs a handler when the mouse is clicked
 *
 * @param parameters
 * @param parameters.elementRefs - refs for elements which when clicked outside of, will call the handler
 * @param parameters.onOutsideClick - handler to call when click happens outside of elements
 */
const useMouseTracker = ({
  elementRefs,
  onOutsideClick,
}: {
  readonly elementRefs: readonly (
    | MutableRefObject<HTMLElement | undefined>
    | RefObject<HTMLElement>
  )[];
  readonly onOutsideClick: () => void;
}) => {
  const mouseTrackerRef = useRef({ isMouseDown: false });

  useEffect(() => {
    const onMouseDown = (event: globalThis.MouseEvent) => {
      mouseTrackerRef.current.isMouseDown = true;

      if (!event.target) return;

      const elements = elementRefs.flatMap((element) =>
        !!element.current ? [element.current] : [],
      );

      if (targetWithin(event.target as HTMLElement, elements)) return;

      onOutsideClick();
    };

    const onMouseUp = () => {
      mouseTrackerRef.current.isMouseDown = false;
    };

    window.addEventListener(`mousedown`, onMouseDown);
    window.addEventListener(`mouseup`, onMouseUp);

    return () => {
      window.removeEventListener(`mousedown`, onMouseDown);
      window.removeEventListener(`mouseup`, onMouseUp);
    };
  }, [elementRefs, onOutsideClick]);

  return mouseTrackerRef.current;
};

export default useMouseTracker;
