import { Textarea, TextareaProps } from "@chakra-ui/react";
import { forwardRef, ForwardedRef } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import InputWrapper, { InputWrapperProps } from "./InputWrapper";

type AreaInputProps = InputWrapperProps &
  TextareaProps &
  Omit<UseFormRegisterReturn, "onBlur">;

const AreaInput = forwardRef<typeof Textarea, AreaInputProps>(
  (
    { name, ...inputProps }: AreaInputProps,
    ref: ForwardedRef<typeof Textarea>,
  ) => (
    <InputWrapper name={name} {...inputProps}>
      <Textarea resize="none" name={name} {...inputProps} ref={ref} />
    </InputWrapper>
  ),
);

export default AreaInput;
