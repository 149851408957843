import { ListItem } from "@chakra-ui/react";
import { ReactNode } from "react";
import { UseComboboxGetItemProps } from "@/modules/Form";

interface ComboboxItemProps<TItem> {
  readonly highlightedIndex: number;
  readonly index: number;
  readonly isDisabled?: boolean;
  readonly getItemProps: UseComboboxGetItemProps;
  readonly item: TItem;
  readonly children: ReactNode;
}
export default function ComboboxItem<TItem>({
  children,
  getItemProps,
  isDisabled,
  index,
}: ComboboxItemProps<TItem>) {
  return (
    <ListItem {...getItemProps({ index, isDisabled })}>{children}</ListItem>
  );
}
