import { ForwardedRef, ReactNode, forwardRef, useState } from "react";

import {
  FormControl,
  FormErrorMessage,
  HStack,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from "@chakra-ui/react";

import { RefCallBack } from "react-hook-form";
import isNil from "lodash/isNil";
import { useCombobox, InputProps } from "@/modules/Form";
import ComboboxLabel from "./ComboboxLabel";
import ComboboxDescription from "./ComboboxDescription";
import ComboboxContainer from "./ComboboxContainer";
import ComboboxInput from "./ComboboxInput";
import ComboboxMenu from "./ComboboxMenu";
import ComboboxItem from "./ComboboxItem";

type Props<TItem> = InputProps & {
  description?: string;
  isLoading?: boolean;
  getItems: ({ search }: { readonly search: string }) => TItem[];
  getItemKey: (item: TItem) => string;
  getItemLabel: (item: TItem) => string;
  getItemLogo?: (item: TItem) => ReactNode;
  setValue: (value: TItem) => void;
  getSearchValue?: (text: string) => void;
  leftElement?: ReactNode;
  rightElement?: ReactNode;
};

function ComboboxRootComponent<TItem>(
  {
    label,
    name,
    error,
    description,
    isLoading,
    ref: _,
    getItems,
    getItemKey,
    getItemLabel,
    getItemLogo,
    setValue,
    getSearchValue,
    leftElement,
    rightElement,
    ...restInputProps
  }: Props<TItem>,
  ref: ForwardedRef<typeof ComboboxRootComponent>,
) {
  const [search, setSearch] = useState(``);
  const [selectedItem, setSelectedItem] = useState<TItem | null>();

  const items = getItems({ search });

  const {
    inputProps: { getInputProps },
    menuProps,
    labelProps,
    itemProps,
  } = useCombobox<TItem>({
    items,
    initialItems: getItems({ search: `` }),
    getItemKey,
    selectedItem,
    onSelectItem: (item: TItem | null) => {
      if (isNil(item)) return;
      setValue(item);
      setSelectedItem(item);
    },
    inputValue: search,
    onChangeInputValue: (inputValue) => {
      getSearchValue?.(inputValue);
      setSearch(inputValue);
    },
    isLoading: isLoading ?? false,
  });

  return (
    <FormControl id={name} isInvalid={!!error}>
      <ComboboxLabel {...labelProps}>{label}</ComboboxLabel>
      {description && <ComboboxDescription description={description} />}
      <ComboboxContainer>
        <InputGroup w="full">
          {leftElement && <InputLeftElement>{leftElement}</InputLeftElement>}
          <ComboboxInput
            name={name}
            getInputProps={() => getInputProps({ ref: ref as RefCallBack })}
            pl={leftElement ? 9 : 0}
            pr={rightElement ? 9 : 0}
            {...restInputProps}
            ref={ref as RefCallBack}
          />
          {rightElement && (
            <InputRightElement mt={label ? `27px` : 0}>
              {rightElement}
            </InputRightElement>
          )}
        </InputGroup>
        <ComboboxMenu isLoading={isLoading} {...menuProps}>
          {items.map((item, index) => (
            <ComboboxItem
              key={getItemKey(item)}
              item={item}
              index={index}
              {...itemProps}
            >
              <HStack>
                {getItemLogo && getItemLogo(item)}
                <Text as="span">{getItemLabel(item)}</Text>
              </HStack>
            </ComboboxItem>
          ))}
        </ComboboxMenu>
      </ComboboxContainer>
      {!!error && <FormErrorMessage>{error?.message}</FormErrorMessage>}
    </FormControl>
  );
}

const ComboboxRoot = forwardRef<typeof ComboboxRootComponent, Props<unknown>>(
  ComboboxRootComponent,
) as <TItem>(
  p: Props<TItem> & React.RefAttributes<HTMLDivElement>,
) => ReactNode;

export default ComboboxRoot;
