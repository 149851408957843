import isNil from "lodash/isNil";
import { useState } from "react";

import {
  FormControl,
  FormErrorMessage,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";

import {
  Combobox,
  useComboboxItemField,
  useComboboxPatch,
} from "@/modules/Form";
import { UseControllerProps } from "react-hook-form";
import { ComboboxPatchInput } from "./ComboboxPatchInput";

type ComboboxPatchProps<TItem> = {
  placeholder?: string;
  getItems: ({ search }: { search: string }) => TItem[];
  control: UseControllerProps;
  onChangeSearch?: (search: string) => void;
  itemToString: (item: TItem) => string;
  getItemKey: (item: TItem) => string;
  getItemDisabled?: (item: TItem) => boolean;
  setSelectedItem: (value: TItem | null) => void;
  label: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  leftElement?: React.ReactNode;
};

export function ComboboxPatch<TItem>({
  placeholder,
  getItems,
  control,
  setSelectedItem,
  onChangeSearch,
  itemToString,
  getItemKey,
  getItemDisabled = () => false,
  label,
  isLoading,
  isDisabled,
  leftElement,
}: ComboboxPatchProps<TItem>) {
  const { error, isInvalid, selectedItem, name } =
    useComboboxItemField<TItem>(control);

  const [search, setSearch] = useState(
    !!selectedItem ? itemToString(selectedItem) : ``,
  );

  const handleChangeInputValue = (inputValue: string) => {
    if (!isNil(onChangeSearch)) {
      onChangeSearch(inputValue);
    }

    setSearch(inputValue);
  };

  const items = getItems({ search });

  const { inputProps, menuProps, labelProps, itemProps } =
    useComboboxPatch<TItem>({
      items,
      getItemKey,
      selectedItem,
      onSelectItem: (item: TItem | null) => {
        setSelectedItem(item);
        if (!isNil(item)) handleChangeInputValue(itemToString(item));
      },
      inputValue: search,
      onChangeInputValue: handleChangeInputValue,
      isLoading,
    });

  return (
    <FormControl id={name} isInvalid={isInvalid || !!error?.message}>
      <Combobox.Label {...labelProps}>{label}</Combobox.Label>
      <Combobox.Container>
        <InputGroup w="full">
          {leftElement && <InputLeftElement>{leftElement}</InputLeftElement>}
          <ComboboxPatchInput
            isDisabled={isDisabled}
            name={name}
            placeholder={placeholder}
            pl={leftElement ? 9 : undefined}
            {...inputProps}
          />
        </InputGroup>
        <Combobox.Menu isLoading={isLoading} {...menuProps}>
          {items.map((item, index) => (
            <Combobox.Item
              key={getItemKey(item)}
              isDisabled={getItemDisabled(item)}
              item={item}
              index={index}
              {...itemProps}
            >
              {itemToString(item)}
            </Combobox.Item>
          ))}
        </Combobox.Menu>
      </Combobox.Container>
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
}
