import { LoginInput, useAuthLoginMutation } from "@/graphql";
import { setAuth } from "@/state/auth";
import { useCallback } from "react";
import { UseFormSetError } from "react-hook-form";
import { useDispatch } from "react-redux";

type AccountSignInErrorField = "email" | "root" | `root.${string}`;

interface UseSignInProps {
  setError: UseFormSetError<LoginInput>;
}

export function useSignIn({ setError }: UseSignInProps) {
  const dispatch = useDispatch();
  const [, signIn] = useAuthLoginMutation();

  return useCallback(
    async (payload: LoginInput) => {
      const { data } = await signIn(payload);

      if (data?.login.errors?.length) {
        data.login.errors.forEach(({ field, message }) =>
          setError(field as AccountSignInErrorField, { message }),
        );
        return;
      }

      if (data?.login.session?.token) {
        const { currentUser: _user, ...session } = data.login.session;
        dispatch(setAuth(session));
      } else {
        setError(`root`, { message: `No session was returned!` });
      }
    },
    [dispatch, setError, signIn],
  );
}
