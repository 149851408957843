import { ReactElement, ReactNode } from "react";
import {
  BoxProps,
  HStack,
  MenuButton,
  Text,
  MenuList,
  MenuItem,
  Menu,
  Wrap,
  WrapItem,
  Checkbox,
  MenuProps,
} from "@chakra-ui/react";
import { CaretDown } from "@phosphor-icons/react";
import * as yup from "yup";
import { FieldError, Merge } from "react-hook-form";
import { InputWrapper } from "@/modules/Form";
import { FilterTag } from "@/modules/FilterTag";
import { shadows } from "@/modules/Theme";

type WrapperProps = BoxProps & {
  readonly name: string;
  readonly helperText: string;
  readonly label: string;
  readonly error?:
    | yup.ValidationError
    | Merge<FieldError, (FieldError | undefined)[]>;
  readonly children: ReactNode;
};

type ButtonProps = {
  readonly isInvalid: boolean;
  readonly prompt: string;
  readonly iconRight?: ReactElement;
};

type ItemProps<T> = {
  readonly onClick: (value: T) => void;
  readonly value: T;
  readonly displayValue: string;
};

type ContainerProps = {
  readonly children: ReactNode;
};

type TagProps<T> = {
  readonly value: T;
  readonly displayValue: string;
  readonly onClick: (value: T) => void;
};

function Wrapper({
  name,
  helperText,
  label,
  error,
  w = `full`,
  children,
  ...boxProps
}: WrapperProps) {
  return (
    <InputWrapper
      name={name}
      helperText={helperText}
      label={label}
      w={w}
      error={error}
      {...boxProps}
    >
      {children}
    </InputWrapper>
  );
}

function Button({ isInvalid, prompt, iconRight = <CaretDown /> }: ButtonProps) {
  return (
    <MenuButton
      border="solid grey.300"
      borderColor={isInvalid ? `archived` : `grey.300`}
      w="full"
      _active={{ boxShadow: shadows.focus }}
    >
      <HStack justifyContent="space-between">
        <Text color="grey.400">{prompt}</Text>
        {iconRight}
      </HStack>
    </MenuButton>
  );
}

function Item<T>({ onClick, value, displayValue }: ItemProps<T>) {
  return (
    <MenuItem key={`${displayValue}`} onClick={() => onClick(value)}>
      <Text>{displayValue}</Text>
    </MenuItem>
  );
}

function CheckableItem<T>({
  onClick,
  value,
  displayValue,
  isChecked = false,
}: ItemProps<T> & {
  readonly isChecked?: boolean;
}) {
  return (
    <MenuItem key={`${displayValue}`} onClick={() => onClick(value)}>
      <HStack spacing={3}>
        <Checkbox
          isInvalid={false} // avoid showing error state in Item, error is shown in Button
          size="lg"
          id={displayValue}
          name={displayValue}
          isChecked={isChecked}
          pointerEvents="none"
        />
        <Text>{displayValue}</Text>
      </HStack>
    </MenuItem>
  );
}

function ItemsContainer({ children }: ContainerProps) {
  return <MenuList w="full">{children}</MenuList>;
}

function SelectContainer({
  children,
  ...menuProps
}: MenuProps & ContainerProps) {
  return (
    <Menu matchWidth {...menuProps}>
      {children}
    </Menu>
  );
}

function TagContainer({ children, ...boxProps }: BoxProps & ContainerProps) {
  return <Wrap {...boxProps}>{children}</Wrap>;
}

function Tag<T>({ value, displayValue, onClick }: TagProps<T>) {
  return (
    <WrapItem key={displayValue}>
      <FilterTag name={displayValue} onClick={() => onClick(value)} />
    </WrapItem>
  );
}

export default {
  Wrapper,
  Button,
  Item,
  CheckableItem,
  ItemsContainer,
  Container: SelectContainer,
  TagContainer,
  Tag,
};
