import { KeyboardEvent } from "react";

/**
 * Normalizes the 'key' property of a KeyboardEvent in IE/Edge
 * @param event a keyboardEvent object
 * @return keyboard key
 */
const convertKeyForLegacySupport = <T>(event: KeyboardEvent<T>) => {
  const { key, keyCode } = event;
  if (keyCode >= 37 && keyCode <= 40 && key.indexOf(`Arrow`) !== 0) {
    return `Arrow${key}`;
  }
  return key;
};

export default convertKeyForLegacySupport;
