import { Flex, Text } from "@chakra-ui/react";
import { ReactNode } from "react";

type NotFoundErrorProps = {
  title: ReactNode;
  description: ReactNode;
  subText?: ReactNode;
};

function NotFoundError({ title, description, subText }: NotFoundErrorProps) {
  return (
    <Flex direction="column" alignItems="center" pt={{ base: 10, md: 20 }}>
      <Text textStyle="heading-5xl" textAlign="center">
        {title}
      </Text>
      <Text textStyle="colfax-16-regular" textAlign="center" m={5}>
        {description}
      </Text>
      <Text fontWeight="bold">{subText ?? `Please try again later.`}</Text>
    </Flex>
  );
}

export default NotFoundError;
