import { listAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

export default defineMultiStyleConfig({
  baseStyle: {},
  variants: {
    menu: {
      container: {
        top: 12,
        overflow: `auto`,
        color: `grey.900`,
        position: `absolute`,
        bg: `white`,
        w: `full`,
        borderWidth: 1,
        boxShadow: `card`,
        borderColor: `grey.200`,
        overflowY: `auto`,
        borderRadius: `md`,
        zIndex: `sticky`,
        maxH: `206px`,
      },
      item: {
        cursor: `pointer`,
        px: 5,
        py: 2.5,
        textStyle: `heading-xs`,
        borderBottomWidth: 1,
        borderColor: `grey.50`,
        bg: `transparent`,
        _selected: {
          bg: `grey.50`,
        },
        _hover: {
          bg: `grey.50`,
        },
        _active: {
          bg: `grey.75`,
        },
        transition: `.1s background-color ease-in-out`,
        _disabled: {
          bg: `transparent`,
          cursor: `default`,
          color: `grey.300`,
        },
        _last: {
          borderBottomWidth: 0,
        },
      },
    },
  },
});
