import { HiiveFullLogo } from "@/modules/Assets";
import { Box, Center, VStack } from "@chakra-ui/react";
import random from "lodash/random";
import { ReactNode, useMemo } from "react";

const WINNING_NUMBER = 1;

interface AccountContainerProps {
  children: ReactNode;
}

export default function AccountContainer({ children }: AccountContainerProps) {
  const randomNumber = useMemo(() => random(10, false), []);

  return (
    <main>
      <VStack h="85vh" w="full" alignItems="center" justifyContent="center">
        <Box maxW="30rem" mx="auto" position="relative" w="full">
          <Center mb={10}>
            {randomNumber === WINNING_NUMBER ? (
              <HiiveFullLogo.DarkGreyWithBee />
            ) : (
              <HiiveFullLogo.DarkGreyWithPink />
            )}
          </Center>
          {children}
        </Box>
      </VStack>
    </main>
  );
}
