import { defineStyleConfig } from "@chakra-ui/react";

const baseStyle = {
  borderColor: `grey.300`,
  borderWidth: `.5px`,
  bg: `white`,
  borderRadius: `md`,
  autoComplete: `off`,
  _disabled: {
    pointerEvents: `none`,
    borderColor: `grey.200`,
    _placeholder: {
      color: `grey.300`,
    },
  },
  _hover: {
    borderColor: `grey.200`,
  },
  _placeholder: {
    color: `grey.600`,
  },
  _focus: {
    boxShadow: `focus`,
    borderColor: `grey.600`,
  },
  _invalid: {
    borderColor: `archived`,
    borderWidth: `0.5px`,
  },
};

const Textarea = defineStyleConfig({
  baseStyle,
  variants: {
    borderless: {
      ...baseStyle,
      border: `none`,
      backgroundColor: `transparent`,
      padding: 1,
      marginX: -1,
      resize: `none`,
    },
  },
});

export default Textarea;
