import i18next from "i18next";
import { CombinedError } from "urql";

export function handleGqlError(error: CombinedError): string {
  const [gqlError] = error?.graphQLErrors || [];
  const { originalError } = gqlError || {};
  const { code, message } = (originalError || {}) as Error & {
    code?: string;
  };
  const errorKey = code?.toLowerCase() as unknown as TemplateStringsArray;
  return errorKey ? i18next.t(errorKey, { defaultValue: message }) : message;
}
