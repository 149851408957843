/* eslint-disable no-param-reassign */
/* eslint-disable functional/immutable-data */
import { SessionUser } from "@/graphql";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type AuthState = {
  token?: string | null;
  user?: SessionUser | null;
  refreshToken?: string | null;
};

const INITIAL_STATE: AuthState = {
  token: null,
  user: null,
  refreshToken: null,
};

const authSlice = createSlice({
  name: `auth`,
  initialState: INITIAL_STATE,
  reducers: {
    setAuth: (state, action: PayloadAction<AuthState>) => {
      const { user, token, refreshToken } = action.payload;
      state.user = user;
      state.token = token;
      state.refreshToken = refreshToken;
    },
    resetAuth: (_state) => INITIAL_STATE,
  },
});

export const { setAuth, resetAuth } = authSlice.actions;
export default authSlice;
