import { accordionAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(accordionAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    mb: 4,
    _last: {
      mb: 0,
    },
    border: `none`,
  },
  button: {
    bg: `white`,
    display: `flex`,
    justifyContent: `space-between`,
    p: 6,
    borderTopRadius: `md`,
  },
  panel: {
    bg: `white`,
    p: 6,
    borderColor: `grey.50`,
    borderTopWidth: 1,
    borderBottomRadius: `md`,
  },
});

const Accordion = defineMultiStyleConfig({ baseStyle });

export default Accordion;
