import "@/i18n";

import { Error500Page } from "@/features/Error";
import { LaunchDarklyProvider, useFeatureFlags } from "@/modules/LaunchDarkly";
import { SessionProvider, useSession } from "@/modules/Session";
import theme, { Fonts } from "@/modules/Theme";
import { TopMenu } from "@/modules/TopMenu";
import { UrqlProvider } from "@/modules/Urql";
import store, { persistor } from "@/state";
import { AbsoluteCenter, Box, Spinner, ChakraProvider } from "@chakra-ui/react";
import * as Sentry from "@sentry/nextjs";
import { SpeedInsights } from "@vercel/speed-insights/next";
import Head from "next/head";
import { ReactNode, useEffect } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { withFronteggApp } from "@frontegg/nextjs/pages";
import type { AppProps } from "next/app";
import "./css/datepicker.css";
import { ROUTES } from "@/constants";
import { useRouterRedirectEffect } from "@/features/Account";
import {
  boxStyle,
  boxTitleStyle,
  containerStyle,
  rootStyle,
  PageHeader,
  PageFooter,
} from "@/modules/Frontegg";
import { useRouter } from "next/router";

const PATTERNS = [
  /^\/$/,
  /^\/account\/(signin|login)$/,
  /^\/account\/login\/callback$/,
  /^\/workflows\/.*$/,
  /^\/workflow-templates\/.*$/,
];

function AppContent({ children }: { children: ReactNode }) {
  const router = useRouter();
  const { pathname } = router;
  const { isAuthenticated, user } = useSession();
  const { loading: fetchingFeatureFlags } = useFeatureFlags();

  useRouterRedirectEffect();

  const isPublicRoute =
    pathname === ROUTES.DEFAULT_LOGIN || pathname === ROUTES.FRONTEGG_LOGIN;
  const isCallbackRoute = pathname === ROUTES.CALLBACK;

  const shouldRenderPage =
    !fetchingFeatureFlags &&
    (isAuthenticated || isPublicRoute || isCallbackRoute);

  useEffect(() => {
    const { id } = Sentry.getCurrentHub().getScope()?.getUser() || {};
    if (!id && user && user.id && user.email) {
      Sentry.setUser({
        email: user.email,
        id: user.id,
      });
    }
  }, [user]);

  if (!shouldRenderPage) {
    return (
      <Box minH="100vh">
        <AbsoluteCenter axis="both">
          <Spinner />
        </AbsoluteCenter>
      </Box>
    );
  }

  const shouldHideTopMenu = PATTERNS.some((pattern) => pattern.test(pathname));

  return (
    <>
      {!shouldHideTopMenu && <TopMenu />}
      {children}
    </>
  );
}

function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <Fonts />
      </Head>
      <Sentry.ErrorBoundary
        fallback={({ error }) => <Error500Page error={error?.message} />}
      >
        <ChakraProvider theme={theme}>
          <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <SessionProvider>
                <LaunchDarklyProvider>
                  <UrqlProvider>
                    <AppContent>
                      <Component {...pageProps} />
                    </AppContent>
                  </UrqlProvider>
                </LaunchDarklyProvider>
              </SessionProvider>
            </PersistGate>
          </ReduxProvider>
        </ChakraProvider>
      </Sentry.ErrorBoundary>
      <SpeedInsights />
    </>
  );
}

export default withFronteggApp(App, {
  hostedLoginBox: false,
  authOptions: {
    keepSessionAlive: true,
  },
  customLoader: () => null,
  themeOptions: {
    loginBox: {
      login: {
        docTitle: `Sign in | Hiive Back Office`,
        signupMessage: ``,
        boxStyle,
        boxTitleStyle,
        containerStyle,
        // @ts-expect-error Need to apply custom style to the root element
        rootStyle,
        pageHeader: PageHeader,
        pageFooter: PageFooter,
      },
    },
  },
  contextOptions: {
    tenantResolver: () => ({ tenant: process.env.NEXT_PUBLIC_FRONTEGG_TENANT }),
  },
});
