/* eslint-disable functional/immutable-data */

/* eslint-disable no-param-reassign */
import { compute } from "compute-scroll-into-view";
import isNil from "lodash/isNil";
import uniqueId from "lodash/uniqueId";
import {
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

import { InputProps, useDisclosure } from "@chakra-ui/react";

import {
  handleRefs,
  useMouseTracker,
  convertKeyForLegacySupport,
} from "@/modules/DOM";
import keys from "lodash/keys";
import {
  UseComboboxGetInputProps,
  UseComboboxGetItemProps,
  UseComboboxGetLabelProps,
  UseComboboxGetMenuProps,
} from "./types";

const useElementIds = () => {
  const id = uniqueId();

  const elementIdsRef = useRef({
    labelId: `${id}-label`,
    inputId: `${id}-input`,
    menuId: `${id}-menu`,
    getItemId: (index: number) => `${id}-item-${index}`,
  });

  return elementIdsRef.current;
};

const getIsNodeDisabled = (node: HTMLElement) =>
  node.getAttribute(`aria-disabled`);

interface UseComboboxProps<TItem> {
  readonly getItemKey: (item: TItem) => string;
  readonly selectedItem?: TItem | null;
  readonly onSelectItem: (item: TItem | null) => void;
  readonly items: TItem[];
  readonly initialItems: TItem[];
  readonly inputValue: InputProps[`value`];
  readonly onChangeInputValue: (inputValue: string) => void;
  readonly isLoading: boolean;
}

/**
 * Hook for a simple controlled combobox component, provides event handler props and accessibility attributes
 */
const useCombobox = <TItem>({
  items,
  initialItems,
  getItemKey,
  selectedItem,
  onSelectItem,
  onChangeInputValue,
  inputValue,
  isLoading,
}: UseComboboxProps<TItem>) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);

  const menuRef = useRef<HTMLUListElement>(null);
  const inputRef = useRef<HTMLInputElement>();

  const itemRefs = useRef<Record<string, HTMLLIElement | null>>({});

  const elementIds = useElementIds();

  const getItemNodeFromIndex = useCallback(
    (index: number) => itemRefs.current[elementIds.getItemId(index)],
    [elementIds],
  );

  const wrapNextIndex = useCallback(
    (index: number) => (index > items.length - 1 ? 0 : index),
    [items.length],
  );

  const wrapPreviousIndex = useCallback(
    (index: number) => (index < 0 ? items.length - 1 : index),
    [items.length],
  );

  const indexOutOfRange = useCallback(
    (index: number) => index < 0 || index > items.length - 1,
    [items.length],
  );

  /**
   * Gets the index of a non-disabled list item to move to
   *
   * @param params
   * @param params.currentIndex - the current index to check
   * @param params.direction - the direction to move in the list, up or down
   * @param params.moveAmount - the number of items to move through
   * @param params.previousEnabledIndex - index of the most recently checked item that was enabled
   * @param params.wrap - whether or not the indicies should wrap around the list of items,
   *                      e.g if we start from the end of the list and move down, we'll move to the first element
   * @param params.disabledItemCount - number items that have been checked which are disabled
   */
  const getMovedIndex = useCallback(
    ({
      currentIndex,
      direction,
      moveAmount,
      previousEnabledIndex = null,
      wrap = true,
      disabledItemCount = 0,
    }: {
      readonly currentIndex: number;
      readonly direction: `up` | `down`;
      readonly moveAmount: number;
      readonly previousEnabledIndex?: number | null;
      readonly wrap?: boolean;
      readonly disabledItemCount?: number;
    }): number | null => {
      if (disabledItemCount === items.length) return null;

      if (indexOutOfRange(currentIndex)) return null;

      const currentNode = getItemNodeFromIndex(currentIndex);

      if (!currentNode) return null;

      const currentNodeEnabled = !getIsNodeDisabled(currentNode);
      const newPreviousEnabledIndex = currentNodeEnabled
        ? currentIndex
        : previousEnabledIndex;

      if (
        !wrap &&
        ((direction === `down` && currentIndex === items.length - 1) ||
          (direction === `up` && currentIndex === 0))
      ) {
        return newPreviousEnabledIndex;
      }

      if (moveAmount === 0 && currentNodeEnabled) {
        return currentIndex;
      }

      const movedIndex =
        direction === `down` ? currentIndex + 1 : currentIndex - 1;

      const wrappedMovedIndex =
        direction === `down`
          ? wrapNextIndex(movedIndex)
          : wrapPreviousIndex(movedIndex);

      const wrappedMovedNode = getItemNodeFromIndex(wrappedMovedIndex);

      if (!wrappedMovedNode) return null;

      const newMoveAmount = currentNodeEnabled ? moveAmount - 1 : moveAmount;
      const newDisabledItemCount = currentNodeEnabled
        ? disabledItemCount
        : disabledItemCount + 1;

      return getMovedIndex({
        currentIndex: wrappedMovedIndex,
        direction,
        moveAmount: newMoveAmount,
        previousEnabledIndex: newPreviousEnabledIndex,
        wrap,
        disabledItemCount: newDisabledItemCount,
      });
    },
    [
      getItemNodeFromIndex,
      indexOutOfRange,
      items.length,
      wrapNextIndex,
      wrapPreviousIndex,
    ],
  );

  const handleMoveHighlightedIndex = useCallback(
    ({
      currentIndex,
      moveAmount,
      wrap = true,
      direction,
    }: {
      readonly currentIndex: number;
      readonly moveAmount: number;
      readonly wrap?: boolean;
      readonly direction: `up` | `down`;
    }) => {
      const movedIndex = getMovedIndex({
        currentIndex,
        direction,
        moveAmount,
        wrap,
      });

      if (isNil(movedIndex)) return;

      setHighlightedIndex(movedIndex);
    },
    [getMovedIndex],
  );

  const handleHighlightFirstIndex = useCallback(() => {
    handleMoveHighlightedIndex({
      currentIndex: 0,
      moveAmount: 0,
      direction: `down`,
    });
  }, [handleMoveHighlightedIndex]);

  const handleHighlightLastIndex = () => {
    handleMoveHighlightedIndex({
      currentIndex: items.length - 1,
      moveAmount: 0,
      direction: `up`,
    });
  };

  const handleHighlightNextIndex = () => {
    handleMoveHighlightedIndex({
      currentIndex: highlightedIndex,
      moveAmount: 1,
      direction: `down`,
    });
  };

  const handleHighlightPreviousIndex = () => {
    handleMoveHighlightedIndex({
      currentIndex: highlightedIndex,
      moveAmount: 1,
      direction: `up`,
    });
  };

  const handleHighlightSelectedItemIndex = useCallback(() => {
    if (
      !selectedItem ||
      initialItems.map(getItemKey).indexOf(getItemKey(selectedItem)) < 0
    )
      return;

    const selectedItemIndex = initialItems
      .map(getItemKey)
      .indexOf(getItemKey(selectedItem));
    if (isNil(selectedItemIndex)) return;

    setHighlightedIndex(selectedItemIndex);
    // TODO: fix this dependancy array. For whatever reason, passing a frozen initalItems
    // causes this function to not reassign the value properly.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getItemKey, selectedItem]);

  /**
   * Resets item refs
   *
   * Highlights the correct item index
   *
   * If an item is selected & visible in the list, highlights the selected item index
   * Otherwise, selects the first non-disabled item index
   *
   *
   * This part of the code is a bit finicky to be fair
   * would be nice to remove the need for isLoading in this effect call & rely on items instead
   * But that would require memoizing or serializing the items every time.
   */

  useEffect(() => {
    if (!isOpen || isLoading) {
      itemRefs.current = {};
      return;
    }

    if (selectedItem) {
      handleHighlightSelectedItemIndex();
      return;
    }

    handleHighlightFirstIndex();
  }, [
    isOpen,
    isLoading,
    inputValue,
    handleHighlightFirstIndex,
    handleHighlightSelectedItemIndex,
    selectedItem,
  ]);

  const handleClose = () => {
    onClose();
    setHighlightedIndex(-1);
  };

  const handleOpen = () => {
    onOpen();
  };

  const handleOutsideClick = () => {
    if (!isOpen) return;
    handleClose();
  };

  const { isMouseDown } = useMouseTracker({
    elementRefs: [menuRef, inputRef],
    onOutsideClick: handleOutsideClick,
  });

  const shouldScrollRef = useRef(true);

  /**
   * Scrolls to items when highlightedIndex changes
   */
  useLayoutEffect(() => {
    if (highlightedIndex < 0 || !isOpen || keys(itemRefs).length === 0) return;

    if (shouldScrollRef.current === false) {
      shouldScrollRef.current = true;
      return;
    }

    const itemNode = getItemNodeFromIndex(highlightedIndex);
    if (!itemNode) return;

    const actions = compute(itemNode, {
      boundary: menuRef.current,
      block: `nearest`,
      scrollMode: `if-needed`,
    });

    actions.forEach(({ el, top, left }) => {
      el.scrollTop = top;
      el.scrollLeft = left;
    });
  }, [getItemNodeFromIndex, highlightedIndex, isOpen]);

  /**
   * Common behavior when selecting an item
   */
  const handleSelect = () => {
    handleClose();
    if (items.length === 0 || highlightedIndex === -1) return;
    onSelectItem(items[highlightedIndex]);
  };

  /**
   * When Combobox is open, highlights next non-disabled item
   *
   * ALT + ↓ - selects currently highlighted item
   */
  const inputHandleArrowDown = (event: KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (!isOpen && items.length > 0) {
      handleOpen();
      return;
    }

    if (!isOpen) return;

    if (highlightedIndex < 0) {
      handleHighlightFirstIndex();
      return;
    }

    if (event.altKey) {
      handleSelect();
      return;
    }

    handleHighlightNextIndex();
  };

  /**
   * When Combobox is open, highlights previous non-disabled item
   *
   * ALT + ↑ - selects current highlighted item
   */
  const inputHandleArrowUp = (event: KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (!isOpen && items.length > 0) {
      handleOpen();
      return;
    }

    if (!isOpen) return;

    if (highlightedIndex < 0) {
      handleHighlightLastIndex();
      return;
    }

    if (event.altKey) {
      handleSelect();
      return;
    }

    handleHighlightPreviousIndex();
  };

  /**
   * When Combobox is open, selects the currently highlighted item & closes the menu
   */
  const inputHandleEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    // IME composing bit
    event.preventDefault();

    if (!isOpen) return;
    handleSelect();
  };

  /**
   * When Combobox is open, selects the currently highlighted item & closes the menu
   */
  const inputHandleTab = () => {
    if (!isOpen) return;
    handleSelect();
  };

  /**
   * Focus first non-disabled item
   */
  const inputHandleHome = (event: KeyboardEvent<HTMLInputElement>) => {
    if (!isOpen) return;
    event.preventDefault();
    handleHighlightFirstIndex();
  };

  /**
   * Focus last non-disabled item
   */
  const inputHandleEnd = (event: KeyboardEvent<HTMLInputElement>) => {
    if (!isOpen) return;
    event.preventDefault();
    handleHighlightLastIndex();
  };

  /**
   * Closes the menu if it's currently open
   *
   * If the menu is closed, clears the input & selected item
   */
  const inputHandleEscape = (event: KeyboardEvent<HTMLInputElement>) => {
    if (
      !isOpen &&
      highlightedIndex === -1 &&
      !selectedItem &&
      isNil(inputValue)
    )
      return;

    event.preventDefault();

    handleClose();

    if (!isOpen) {
      onSelectItem(null);
      onChangeInputValue(``);
    }
  };

  /**
   * Jump back 10 non-disabled elements
   */
  const inputHandlePageUp = (event: KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const moveAmount = highlightedIndex < 0 ? 9 : 10;
    const startIndex =
      highlightedIndex < 0 ? items.length - 1 : highlightedIndex;

    handleMoveHighlightedIndex({
      currentIndex: startIndex,
      moveAmount,
      direction: `up`,
      wrap: false,
    });
  };

  /**
   * Jump forward 10 non-disabled elements
   */
  const inputHandlePageDown = (event: KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const moveAmount = highlightedIndex < 0 ? 9 : 10;
    const startIndex = highlightedIndex < 0 ? 0 : highlightedIndex;

    handleMoveHighlightedIndex({
      currentIndex: startIndex,
      moveAmount,
      direction: `down`,
      wrap: false,
    });
  };

  const inputKeyDownHandlers: Record<
    string,
    (event: KeyboardEvent<HTMLInputElement>) => void
  > = {
    Escape: inputHandleEscape,
    ...(!isLoading && {
      Home: inputHandleHome,
      End: inputHandleEnd,
      PageUp: inputHandlePageUp,
      PageDown: inputHandlePageDown,
      ArrowDown: inputHandleArrowDown,
      ArrowUp: inputHandleArrowUp,
      Enter: inputHandleEnter,
      Tab: inputHandleTab,
    }),
  };

  /**
   * Takes care of keyDown handlers for `<Combobox.Input/>
   */
  const inputHandleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const key = convertKeyForLegacySupport(event);

    if (!key || !inputKeyDownHandlers[key]) return;

    inputKeyDownHandlers[key](event);
  };

  /**
   * Handle changing input value & open the menu if it's closed
   */
  const inputHandleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!isOpen) {
      handleOpen();
    }
    onChangeInputValue(event.target.value);
  };

  /**
   * Close menu when input is blurred via keyboard
   */
  const inputHandleBlur = () => {
    if (!isOpen || isMouseDown) return;

    handleClose();
  };

  /**
   * Open menu when the input is focused
   */
  const inputHandleFocus = () => {
    if (isOpen) return;
    handleOpen();
  };

  const getLabelProps: UseComboboxGetLabelProps = () => ({
    id: elementIds.labelId,
    htmlFor: elementIds.labelId,
  });

  /**
   * Prop getter for combobox input `<Combobox.Input/>`.
   * Includes all event handlers & accessibility related attributes
   */
  const getInputProps: UseComboboxGetInputProps = ({ ref }) => {
    const activeDescendant =
      isOpen && highlightedIndex > -1
        ? elementIds.getItemId(highlightedIndex)
        : ``;

    return {
      ref: handleRefs<HTMLInputElement>([ref, inputRef]),
      "aria-activedescendant": activeDescendant,
      "aria-autocomplete": `list`,
      "aria-controls": elementIds.menuId,
      "aria-expanded": isOpen,
      "aria-labelledby": elementIds.labelId,
      autoComplete: `off`,
      id: elementIds.inputId,
      role: `combobox`,
      value: inputValue,
      onKeyDown: inputHandleKeyDown,
      onChange: inputHandleChange,
      onBlur: inputHandleBlur,
      onFocus: inputHandleFocus,
    };
  };

  /**
   * Prop getter for combobox menu `<Combobox.Menu/>`.
   * Includes all event handlers & accessibility related attributes
   */
  const getMenuProps: UseComboboxGetMenuProps = () => {
    const menuHandleMouseLeave = () => setHighlightedIndex(-1);

    return {
      ref: menuRef,
      id: elementIds.menuId,
      role: `listbox`,
      "aria-labelledby": `${elementIds.labelId}`,
      onMouseLeave: menuHandleMouseLeave,
    };
  };

  /**
   * Prop getter for combobox item `<Combobox.Item/>`.
   * Includes all event handlers & accessibility related attributes
   */
  const getItemProps: UseComboboxGetItemProps = ({
    index,
    isDisabled,
  }: {
    readonly index: number;
    readonly isDisabled?: boolean;
  }) => {
    const itemHandleMouseMove = () => {
      if (index === highlightedIndex) return;

      shouldScrollRef.current = false;

      if (isDisabled) {
        setHighlightedIndex(-1);
        return;
      }

      setHighlightedIndex(index);
    };

    const itemHandleClick = () => {
      handleClose();

      onSelectItem(items[index]);
    };

    const itemHandleMouseDown = (event: MouseEvent<HTMLLIElement>) => {
      event.preventDefault();
    };

    return {
      ref: handleRefs([
        (node: HTMLLIElement) => {
          itemRefs.current[elementIds.getItemId(index)] = node;
        },
      ]),
      "aria-selected": index === highlightedIndex,
      id: elementIds.getItemId(index),
      onClick: itemHandleClick,
      onMouseMove: itemHandleMouseMove,
      onMouseDown: itemHandleMouseDown,
      role: `option`,
      ...(isDisabled && {
        "aria-disabled": true,
      }),
    };
  };

  const focus = () => {
    if (!inputRef.current) return;
    inputRef.current.focus();
  };

  const blur = () => {
    if (!inputRef.current) return;
    inputRef.current.blur();
  };

  const actions = { focus, blur };

  const inputProps = {
    getInputProps,
  };

  const itemProps = {
    highlightedIndex,
    getItemProps,
  };

  const menuProps = {
    isOpen,
    items,
    getMenuProps,
  };

  const labelProps = {
    getLabelProps,
  };

  return {
    inputProps,
    itemProps,
    menuProps,
    labelProps,
    actions,
  };
};

export default useCombobox;
