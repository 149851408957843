import {
  createMultiStyleConfigHelpers,
  defineStyleConfig,
} from "@chakra-ui/react";

import { tabsAnatomy } from "@chakra-ui/anatomy";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tabsAnatomy.keys,
);

const Tabs = defineStyleConfig(
  defineMultiStyleConfig({
    variants: {
      line: {
        tab: {
          px: 6,
          textStyle: `heading-xs`,
          color: `grey.500`,
          position: `relative`,
          _selected: {
            color: `grey.800`,
            _after: {
              content: `""`,
              position: `absolute`,
              left: `50%`,
              transform: `translateX(-50%)`,
              bottom: `-4px`,
              width: `100%`,
              height: `4px`,
              backgroundColor: `grey.800`,
              borderTopLeftRadius: `4px`,
              borderTopRightRadius: `4px`,
            },
          },
        },
        tabpanels: {
          position: `relative`,
          top: `4px`,
        },
      },
      "outline-black": {
        tablist: {
          p: 0,
          gap: 4,
        },
        tab: {
          borderWidth: `2px`,
          borderRadius: `xl`,
          borderColor: `grey.15`,
          textStyle: `heading-xs`,

          p: 4,
          color: `grey.500`,
          _hover: {
            bg: `grey.25`,
            borderColor: `grey.25`,
          },
          _active: {
            bg: `grey.50`,
          },
          _selected: {
            color: `grey.800`,
            borderColor: `grey.800`,
          },
        },
        tabpanel: {
          p: 0,
        },
      },
      table: {
        tablist: {
          gap: 4,
          p: 4,
          w: `fit-content`,
          bgColor: `white`,
        },
        tab: {
          textStyle: `colfax-16-medium`,
          color: `grey.800`,
          borderWidth: 2,
          borderStyle: `solid`,
          borderColor: `white`,
          borderRadius: 12,
          _focus: {
            boxShadow: `none`,
          },
          _selected: {
            borderColor: `grey.900`,
          },
          py: 2,
          px: 4,
        },
      },
    },
  }),
);

export default Tabs;
