import { useCallback } from "react";
import * as yup from "yup";

const useYupValidationResolver = <
  T extends Record<string, unknown> = Record<string, unknown>,
>(
  validationSchema: yup.ObjectSchema<T>,
) =>
  useCallback(
    async (data: Record<string, unknown>) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors: unknown) {
        return {
          values: {},
          errors: (errors as yup.ValidationError).inner.reduce(
            (allErrors, currentError) => {
              if (!currentError.path) {
                return allErrors;
              }

              return {
                ...allErrors,
                [currentError.path]: {
                  type: currentError.type ?? `validation`,
                  message: currentError.message,
                },
              };
            },
            {},
          ),
        };
      }
    },
    [validationSchema],
  );

export default useYupValidationResolver;
