import { ForwardedRef, forwardRef } from "react";
import { Switch, SwitchProps } from "@chakra-ui/react";
import { useHookFormMask } from "use-mask-input";
import InputWrapper, { InputWrapperProps } from "./InputWrapper";

type SwitchInputProps = InputWrapperProps &
  SwitchProps &
  ReturnType<ReturnType<typeof useHookFormMask>>;

const SwitchInput = forwardRef<typeof Switch, SwitchInputProps>(
  (
    { name, ...switchProps }: SwitchInputProps,
    ref: ForwardedRef<typeof Switch>,
  ) => (
    <InputWrapper name={name} {...switchProps}>
      <Switch name={name} {...switchProps} ref={ref} />
    </InputWrapper>
  ),
);

export default SwitchInput;
