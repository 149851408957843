import { ControllerRenderProps } from "react-hook-form";
import { NumericFormat } from "react-number-format";

import { FeeDiscountFormInputs } from "@/features/FeeDiscountForm";
import {
  InputIconProps,
  InputWrapper,
  InputWrapperProps,
} from "@/modules/Form";
import {
  Input as ChakraInput,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";

type DecimalInputWithSeparatorProps = InputWrapperProps &
  InputIconProps & {
    field: ControllerRenderProps<FeeDiscountFormInputs, "value">;
    decimalScale?: number;
  };

export default function DecimalInputWithSeparator({
  error,
  field,
  iconLeft,
  label,
  name,
  decimalScale,
}: DecimalInputWithSeparatorProps) {
  return (
    <InputGroup w="full">
      {iconLeft && <InputLeftElement mt="27px">{iconLeft}</InputLeftElement>}

      <InputWrapper label={label} name={name} error={error}>
        <NumericFormat
          allowNegative={false}
          customInput={ChakraInput}
          decimalScale={decimalScale || 2}
          pl={10}
          thousandSeparator
          {...field}
        />
      </InputWrapper>
    </InputGroup>
  );
}
