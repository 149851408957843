const textTokens = {
  "text-xs": {
    fontSize: `0.75rem`,
    lineHeight: `1.125rem`,
    fontWeight: `normal`,
  },
  "text-sm": {
    fontSize: `0.875rem`,
    lineHeight: `1.25rem`,
    fontWeight: `normal`,
  },
  "text-md": {
    fontSize: `1rem`,
    lineHeight: `1.5rem`,
    fontWeight: `normal`,
  },
  "text-lg": {
    fontSize: `1.125rem`,
    lineHeight: `1.375rem`,
    fontWeight: `normal`,
  },
  "text-xl": {
    fontSize: `1.25rem`,
    lineHeight: `1.5rem`,
    fontWeight: `normal`,
  },
  "text-2xl": {
    fontSize: `1.375rem`,
    lineHeight: `1.625rem`,
    fontWeight: `normal`,
  },
  "text-3xl": {
    fontSize: `1.5rem`,
    lineHeight: `1.813rem`,
    fontWeight: `normal`,
  },
  "text-4xl": {
    fontSize: `2rem`,
    lineHeight: `2.375rem`,
    fontWeight: `normal`,
  },
};

const headingTokens = {
  "heading-5xs": {
    fontWeight: `medium`,
    fontSize: `0.625rem`,
    lineHeight: `0.9375rem`,
  },
  "heading-4xs": {
    fontWeight: `medium`,
    fontSize: `0.6875rem`,
    lineHeight: `1rem`,
  },
  "heading-3xs": {
    fontWeight: 500,
    fontSize: `0.75rem`,
    lineHeight: `1.5rem`,
  },
  "heading-2xs": {
    textTransform: `uppercase`,
    fontWeight: `medium`,
    fontSize: `.813rem`,
    letterSpacing: `0.05rem`,
    lineHeight: `1rem`,
  },
  "heading-xs": {
    fontSize: `0.875rem`,
    lineHeight: `1.25rem`,
    fontWeight: 500,
  },
  "heading-sm": {
    fontSize: `.938rem`,
    fontWeight: `medium`,
    lineHeight: `1rem`,
    textTransform: `uppercase`,
    letterSpacing: `0.05rem`,
  },
  "heading-md": {
    fontWeight: `500`,
    fontSize: `1rem`,
    lineHeight: `1.5rem`,
  },
  "heading-lg": {
    fontSize: `1.125rem`,
    lineHeight: `1.375rem`,
    fontWeight: `500`,
  },
  "heading-xl": {
    fontSize: `1.25rem`,
    lineHeight: `1.5rem`,
    fontWeight: `500`,
  },
  "heading-2xl": {
    fontSize: `1.375rem`,
    lineHeight: `1.625rem`,
    fontWeight: `500`,
  },
  "heading-3xl": {
    fontWeight: `500`,
    fontSize: `1.5rem`,
    lineHeight: `1.813rem`,
  },
  "heading-4xl": {
    fontSize: `2rem`,
    lineHeight: `2.375rem`,
    fontWeight: 500,
  },
  "heading-5xl": {
    fontSize: `2.625rem`,
    lineHeight: `normal`,
    fontWeight: 500,
  },
};

const textStyles = {
  "colfax-12-regular": {
    fontSize: `0.75rem`,
    lineHeight: `0.875rem`,
    fontWeight: 400,
  },
  "colfax-12-medium": {
    fontSize: `0.75rem`,
    lineHeight: `0.875rem`,
    fontWeight: 500,
  },
  "colfax-14-regular": {
    fontSize: `0.875rem`,
    lineHeight: `1.125rem`,
    fontWeight: 400,
  },
  "colfax-14-medium": {
    fontSize: `0.875rem`,
    lineHeight: `1.5rem`,
    textTransform: `capitalize`,
    fontWeight: 500,
  },
  "colfax-14-medium-uncased": {
    fontSize: `0.875rem`,
    lineHeight: `1.5rem`,
    fontWeight: 500,
  },
  "colfax-14-medium-uppercased": {
    fontSize: `0.875rem`,
    lineHeight: `1.5rem`,
    fontWeight: 500,
    textTransform: `uppercase`,
  },
  "colfax-16-regular": {
    fontSize: `1rem`,
    lineHeight: `1.5rem`,
    fontWeight: 400,
  },
  "colfax-16-medium": {
    fontSize: `1rem`,
    lineHeight: `1.5rem`,
    fontWeight: 500,
  },
  "colfax-18-regular": {
    fontSize: `1.125rem`,
    lineHeight: `1.35rem`,
    fontWeight: 400,
  },
  "colfax-18-medium": {
    fontSize: `1.125rem`,
    lineHeight: `1.35rem`,
    fontWeight: 500,
  },
  "colfax-22-medium": {
    fontSize: `1.375rem`,
    lineHeight: `1.65rem`,
    fontWeight: 500,
  },
  sensitive: {
    color: `transparent`,
    textShadow: `0 0 8px #000`,
    filter: `blur(4px)`,
    webkitFilter: `blur(4px)`,
    opacity: `.8`,
  },
  ...textTokens,
  ...headingTokens,
};

export default textStyles;
