import { SessionUser } from "@/graphql";
import { getUserInitials } from "@/modules/ProfileMenu";
import { useSession, useSignOut } from "@/modules/Session";
import {
  Button,
  Divider,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tag,
  TagLabel,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export default function ProfileMenu() {
  const { t } = useTranslation();
  const { user } = useSession();
  const signOut = useSignOut();

  const { email = ``, name = `` } = user || ({} as SessionUser);
  const initials = user ? getUserInitials(user) : ``;

  return (
    <Popover>
      <PopoverTrigger>
        <Button
          bg="white"
          color="grey.900"
          h={10}
          w={10}
          borderRadius="100%"
          textStyle="colfax-16-medium"
          textTransform="uppercase"
        >
          {initials}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverBody p={0}>
          <VStack alignItems="flext-start" gap={3} p={4}>
            <Text color="grey.900" textStyle="colfax-18-medium">
              {name}
            </Text>
            <Tag variant="blue">
              <TagLabel>{t(`super_admin`)}</TagLabel>
            </Tag>
            <Text color="grey.900" textStyle="colfax-12-regular">
              {email}
            </Text>
          </VStack>
          <Divider />

          <Button
            display="flex"
            justifyContent="flex-start"
            w="100%"
            borderTopRadius={0}
            variant="ghost"
            textStyle="colfax-14-medium"
            onClick={signOut}
          >
            {t(`sign_out`)}
          </Button>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
