import { ReactNode } from "react";

import { List, ListProps } from "@chakra-ui/react";

import { UseComboboxGetMenuProps } from "@/modules/Form";
import ComboboxMenuEmptyState from "./ComboboxMenuEmptyState";
import ComboboxMenuSkeleton from "./ComboboxMenuSkeleton";

function ComboboxMenuContent<TItem>({
  children,
  items,
  isLoading,
  fallback,
}: {
  readonly children: ReactNode;
  readonly items: TItem[];
  readonly isLoading?: boolean;
  readonly fallback?: ReactNode;
}) {
  if (isLoading) return fallback || <ComboboxMenuSkeleton />;

  if (items.length === 0) return <ComboboxMenuEmptyState />;

  return children;
}

interface ComboboxMenuProps<TItem> extends ListProps {
  readonly children: ReactNode;
  readonly isOpen: boolean;
  readonly items: TItem[];
  readonly isLoading?: boolean;
  readonly isLazy?: boolean;
  readonly getMenuProps: UseComboboxGetMenuProps;
  readonly fallback?: ReactNode;
}

export default function ComboboxMenu<TOptionValue>({
  isOpen,
  children,
  items,
  isLoading,
  isLazy = true,
  getMenuProps,
  fallback,
  ...listProps
}: ComboboxMenuProps<TOptionValue>) {
  return (
    <List
      variant="menu"
      display={isOpen ? `block` : `none`}
      {...getMenuProps()}
      {...listProps}
    >
      {(isOpen || !isLazy) && (
        <ComboboxMenuContent
          items={items}
          isLoading={isLoading}
          fallback={fallback}
        >
          {children}
        </ComboboxMenuContent>
      )}
    </List>
  );
}
