import { Box } from "@chakra-ui/react";
import { ReactNode } from "react";

function ErrorCallOut({ children }: { children: ReactNode }) {
  return (
    <Box
      w={{ base: `full`, lg: 340 }}
      display="flex"
      alignItems="center"
      gap={3}
      px={3}
      py={4}
      borderRadius="md"
      borderWidth={0.5}
      boxShadow="card"
      bg="salmon.50"
      borderColor="red.700"
    >
      {children}
    </Box>
  );
}

export default ErrorCallOut;
