import { useSession } from "@/modules/Session";
import { setupClient } from "@/modules/Urql";
import { createClient } from "@urql/core";
import { useMemo } from "react";
import { useServerPreview } from "./useServerPreview";

const useUrqlClient = () => {
  const { token = ``, isAuthenticated } = useSession();
  const apiUrl = useServerPreview();

  return useMemo(() => {
    const clientOptions = setupClient({
      isAuthenticated,
      isClientSsrExchange: true,
      token,
      url: apiUrl,
    });

    const client = createClient(clientOptions);
    return client;
  }, [token, apiUrl, isAuthenticated]);
};

export default useUrqlClient;
