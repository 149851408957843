import { Input } from "@chakra-ui/react";
import { ForwardedRef, forwardRef } from "react";
import { InputProps } from "./Input";
import InputWrapper from "./InputWrapper";

const GenericInput = forwardRef<typeof Input, InputProps>(
  (
    { name, formLabelProps, formLabelPosition, ...inputProps }: InputProps,
    ref: ForwardedRef<typeof Input>,
  ) => (
    <InputWrapper
      name={name}
      formLabelProps={formLabelProps}
      formLabelPosition={formLabelPosition}
      {...inputProps}
    >
      <Input id={name} name={name} {...inputProps} ref={ref} />
    </InputWrapper>
  ),
);

export default GenericInput;
