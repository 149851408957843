import { defineStyleConfig } from "@chakra-ui/react";

const FormLabel = defineStyleConfig({
  baseStyle: {
    mb: `1.5`,
  },
  variants: {
    input: {
      color: `grey.600`,
      textTransform: `uppercase`,
      fontWeight: 500,
      fontSize: 14,
    },
  },
});

export default FormLabel;
