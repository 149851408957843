import { X } from "@phosphor-icons/react";
import { Button, ButtonProps } from "@chakra-ui/react";

interface FilterTagProps extends ButtonProps {
  name: string;
  onClick: () => void;
}

export default function FilterTag({ name, onClick, ...props }: FilterTagProps) {
  return (
    <Button
      bgColor="sky.100"
      _hover={{ bg: `sky.200` }}
      color="grey.900"
      rightIcon={<X size={16} />}
      iconSpacing={4}
      onClick={onClick}
      {...props}
    >
      {name}
    </Button>
  );
}
