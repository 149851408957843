import { ForwardedRef, forwardRef } from "react";
import { Input } from "@chakra-ui/react";
import GenericInput from "./GenericInput";
import { InputProps } from "./Input";

const DecimalInput = forwardRef<typeof Input, InputProps>(
  (
    { type: _, onChange, name, ...inputProps }: InputProps,
    ref: ForwardedRef<typeof Input>,
  ) => (
    <GenericInput
      type="text"
      name={name}
      inputMode="decimal"
      onWheel={(e) => e.currentTarget.blur()}
      onChange={onChange}
      {...inputProps}
      ref={ref}
    />
  ),
);

export default DecimalInput;
