import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { LoginInput } from "@/graphql";
import { Input } from "@/modules/Form";
import { useSignIn } from "@/modules/Session";
import { Button, Flex, GridItem, SimpleGrid } from "@chakra-ui/react";

export default function AccountSignInForm() {
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<LoginInput>();

  const signIn = useSignIn({ setError });

  return (
    <form onSubmit={handleSubmit(signIn)}>
      <SimpleGrid rowGap={6} w="full">
        <GridItem colSpan={1}>
          <Input.Email
            id="email"
            label={t(`email`)}
            placeholder={t(`email_address`)}
            error={errors.email}
            {...register(`email`, {
              required: t(`this_is_required`),
            })}
          />
        </GridItem>
        <GridItem colSpan={1}>
          <Input.Password
            id="password"
            label={t(`password`)}
            placeholder={t(`enter_password`)}
            error={errors.password}
            {...register(`password`, {
              required: t(`this_is_required`),
            })}
          />
        </GridItem>
      </SimpleGrid>
      <Flex
        flexDirection="row-reverse"
        alignItems="center"
        justify="space-between"
        mt={10}
      >
        <Button
          type="submit"
          variant="hiive"
          size="md"
          w={150}
          isLoading={isSubmitting}
        >
          {t(`submit`)}
        </Button>
      </Flex>
    </form>
  );
}
