import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const ghost = defineStyle({
  bg: `transparent`,
  borderColor: `transparent`,
  _hover: {
    bg: `grey.50`,
  },
});

const outline = defineStyle({
  bg: `white`,
  borderColor: `grey.200`,
  borderRadius: `md`,
  borderWidth: 1,
  _hover: {
    bg: `grey.50`,
  },
});

const IconButton = defineStyleConfig({
  variants: { ghost, outline },
  sizes: {
    xs: {
      w: `28px`,
      h: `28px`,
    },
  },
});

export default IconButton;
