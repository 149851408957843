import assign from "lodash/assign";
import ComboboxClearButton from "./ComboboxClearButton";
import ComboboxInput from "./ComboboxInput";
import ComboboxItem from "./ComboboxItem";
import ComboboxLabel from "./ComboboxLabel";
import ComboboxMenu from "./ComboboxMenu";
import ComboboxDescription from "./ComboboxDescription";
import ComboboxContainer from "./ComboboxContainer";
import ComboboxRoot from "./ComboboxRoot";

export default assign(ComboboxRoot, {
  Container: ComboboxContainer,
  Input: ComboboxInput,
  Menu: ComboboxMenu,
  Item: ComboboxItem,
  Label: ComboboxLabel,
  Description: ComboboxDescription,
  ClearButton: ComboboxClearButton,
});
