/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import dayjs from "dayjs";
import { Trans } from "react-i18next";

function PageFooter() {
  const currentYear = dayjs().year();

  const baseStyles = {
    alignItems: `center`,
    display: `flex`,
    fontFamily: `var(--chakra-fonts-body)`,
    fontSize: `0.875rem`,
    gap: `1rem`,
    maxWidth: `50rem`,
    paddingBottom: `2rem`,
    paddingLeft: `1rem`,
    paddingRight: `1rem`,
  };

  return (
    <div style={baseStyles}>
      <p style={{ textAlign: `center` }}>
        <Trans
          i18nKey="auth_footer_text"
          components={[
            <a
              key="finra"
              target="_blank"
              href="https://www.finra.org/"
              rel="noreferrer"
              style={{ textDecoration: `underline` }}
            />,
            <a
              key="sipc"
              target="_blank"
              href="https://www.sipc.org/"
              rel="noreferrer"
              style={{ textDecoration: `underline` }}
            />,
            <a
              key="brokercheck"
              target="_blank"
              href="https://brokercheck.finra.org/firm/summary/316580"
              rel="noreferrer"
              style={{ textDecoration: `underline` }}
            />,
            <a
              key="terms"
              target="_blank"
              href="https://hiive.com/terms"
              rel="noreferrer"
              style={{ textDecoration: `underline` }}
            />,
            <a
              key="privacy"
              target="_blank"
              href="https://hiive.com/privacy"
              rel="noreferrer"
              style={{ textDecoration: `underline` }}
            />,
            <a
              key="disclosures"
              target="_blank"
              href="https://hiive.com/disclosures"
              rel="noreferrer"
              style={{ textDecoration: `underline` }}
            />,
          ]}
          values={{ currentYear }}
        />
      </p>
    </div>
  );
}

export default PageFooter;
