import { ReactNode } from "react";

import { Box, BoxProps } from "@chakra-ui/react";

interface ComboboxContainerProps extends BoxProps {
  readonly children: ReactNode;
}

export default function ComboboxContainer({
  children,
  ...boxProps
}: ComboboxContainerProps) {
  return (
    <Box position="relative" w="full" h="full" {...boxProps}>
      {children}
    </Box>
  );
}
