import { drawerAnatomy } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  theme as baseTheme,
} from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(drawerAnatomy.keys);

const originalDrawerStyles = baseTheme.components.Drawer.baseStyle;

const baseStyle = definePartsStyle((props) => {
  const existingDrawerStyles =
    typeof originalDrawerStyles === `function`
      ? originalDrawerStyles(props)
      : originalDrawerStyles;

  return {
    ...existingDrawerStyles,
    closeButton: {
      ...existingDrawerStyles?.closeButton,
      borderColor: `grey.100`,
      borderRadius: `md`,
      borderWidth: 1,
      padding: 0,
      position: `absolute`,
      right: `1rem`,
      top: `1rem`,
      w: `24px`,
      h: `24px`,
      svg: {
        w: `10px`,
        h: `10px`,
      },
    },
  };
});

const Drawer = defineMultiStyleConfig({
  baseStyle,
});

export default Drawer;
