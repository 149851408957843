import { CurrentActorFragment } from "@/graphql";
import { RootState } from "@/state";
import { createContext, ReactNode, useMemo } from "react";
import { useSelector } from "react-redux";
import { useAuthUserOrNull } from "@frontegg/nextjs";
import { useCurrentUser } from "@/modules/Session";

type SessionState = {
  token?: string | null;
  user?: CurrentActorFragment | null;
  refreshToken?: string | null;
  isAuthenticated: boolean;
};

type SessionProviderProps = {
  children: ReactNode;
};

const DEFAULT_SESSION = {
  token: null,
  user: null,
  refreshToken: null,
  isAuthenticated: false,
};

export const Session = createContext<SessionState>(DEFAULT_SESSION);

export function SessionProvider({ children }: SessionProviderProps) {
  const hiiveSession = useSelector((state: RootState) => state.auth);
  const fronteggUser = useAuthUserOrNull();

  const session: RootState["auth"] = useMemo(
    () =>
      fronteggUser
        ? {
            token: fronteggUser.accessToken,
            refreshToken: fronteggUser.refreshToken,
          }
        : hiiveSession,
    [fronteggUser, hiiveSession],
  );

  const { token } = session;
  const [{ data }] = useCurrentUser({ token });

  const value = useMemo(
    () => ({
      ...session,
      user: data,
      isAuthenticated: !!token,
    }),
    [data, session, token],
  );

  return <Session.Provider value={value}>{children}</Session.Provider>;
}
