import { Input as ChakraInput, InputProps, forwardRef } from "@chakra-ui/react";

import { UseComboboxGetInputProps } from "@/modules/Form";

interface ComboboxPatchInputProps extends InputProps {
  readonly placeholder?: string;
  readonly isDisabled?: boolean;
  readonly getInputProps: UseComboboxGetInputProps;
}

export const ComboboxPatchInput = forwardRef<ComboboxPatchInputProps, `input`>(
  (
    {
      getInputProps,
      placeholder,
      isDisabled,
      ...rest
    }: ComboboxPatchInputProps,
    ref,
  ) => (
    <ChakraInput
      {...rest}
      placeholder={placeholder}
      isDisabled={isDisabled}
      {...getInputProps({ ref })}
    />
  ),
);
