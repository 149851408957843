const isOrContainsNode = (parent: HTMLElement, child: HTMLElement) =>
  parent === child || parent.contains(child);

/**
 * Checks if the target node is within any of the elements
 */
const targetWithin = (target: HTMLElement, elements: readonly HTMLElement[]) =>
  elements.some((element) => {
    if (!element || !target) return false;

    return isOrContainsNode(element, target);
  });

export default targetWithin;
