import { Input, InputGroup } from "@chakra-ui/react";
import { ForwardedRef, forwardRef } from "react";
import { InputProps } from "./Input";
import GenericInput from "./GenericInput";

const TimeInput = forwardRef<typeof Input, InputProps>(
  (
    { type: _, label, ...inputProps }: InputProps,
    ref: ForwardedRef<typeof Input>,
  ) => (
    <InputGroup w="full">
      <GenericInput type="time" label={label} {...inputProps} ref={ref} />
    </InputGroup>
  ),
);

export default TimeInput;
