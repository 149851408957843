import { ErrorBanner, ErrorContainer } from "@/modules/Error";
import Head from "next/head";
import { useTranslation } from "react-i18next";

type SomethingWentWrongProps = {
  error?: string | boolean;
};

function SomethingWentWrong({ error }: SomethingWentWrongProps) {
  const { t } = useTranslation();

  return (
    <>
      <Head>
        <title>Oh No! | Hiive BO</title>
      </Head>
      <ErrorContainer>
        <ErrorBanner
          title={t(`something_went_wrong`)}
          description={t(`this_was_unexpected`)}
          subText={error || t(`internal_server_error`)}
        />
      </ErrorContainer>
    </>
  );
}

export default SomethingWentWrong;
