import { useToken, Theme } from "@chakra-ui/react";

import { Paths } from "@/types";
// eslint-disable-next-line no-restricted-imports
import { extendedTheme } from "../modules";

export type MergedColorsType = Theme["colors"] &
  (typeof extendedTheme)["colors"];

/**
 * Wrapper around Chakra UI useToken, specifically for fetching color tokens from the theme
 */
const useColors = <T extends Paths<MergedColorsType>[]>(tokens: T) => {
  const colorTokens = useToken(`colors`, tokens);
  return colorTokens;
};

export default useColors;
