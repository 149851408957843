const colors = {
  // used for custom colorSchemes
  // some Chakra-UI components have a `colorScheme` prop
  // which takes a color and by default adds '.500' to the value its passed
  // ex. <Switch colorScheme="salmonPink" /> translates to salmonPink.500

  // New color scheme
  purple: {
    25: `#F9F8FC`,
    100: `#EDE9F7`,
    300: `#D34AB5`,
    1000: `#4D387B`,
  },
  grey: {
    15: `#FCFCFC`,
    25: `#F9F9F9`,
    50: `#F0F0F0`,
    100: `#D5D5D5`,
    200: `#C0C0C0`,
    300: `#ABABAB`,
    400: `#969696`,
    500: `#818181`,
    600: `#6C6C6C`,
    700: `#575757`,
    800: `#424242`,
    900: `#323232`,
  },
  green: {
    50: `#E5F4ED`,
  },
  yellow: {
    50: `#FFF4EA`,
    100: `#FFF5EB`,
    200: `#A56428`,
  },
  orange: {
    400: `#E39735`,
  },
  salmon: {
    50: `#FEEFEF`,
    100: `#FCDFDF`,
    200: `#FBCFD0`,
    300: `#F9BFC0`,
    400: `#F8AFB0`,
    500: `#F79FA0`,
    600: `#F58F90`,
    700: `#F47F81`,
    800: `#F26F71`,
    900: `#F15F61`,
  },
  red: {
    50: `#F8E6E6`,
    100: `#F0CDCE`,
    200: `#E9B5B5`,
    300: `#E29C9D`,
    400: `#DA8384`,
    500: `#D36A6C`,
    600: `#CC5153`,
    700: `#C4383A`,
    800: `#BD2022`,
    900: `#B60709`,
  },
  skyBlue: {
    50: `#EFFAFD`,
    100: `#E0F5FC`,
    200: `#D6F0F9`,
    300: `#C0EBF9`,
    400: `#B0E5F7`,
    500: `#A1E0F5`,
    600: `#2191B7`,
    700: `#81D6F2`,
    800: `#E0F5FC`,
    900: `#62CCEF`,
    1000: `#58B8D7`,
    1100: `#4EA3BF`,
    1200: `#458FA7`,
    1300: `#3B7A8F`,
  },
  teal: {
    25: `#F4F8F9`,
    50: `#EBF2F4`,
    100: `#D6E5EA`,
    200: `#C2D9DF`,
    300: `#ADCCD5`,
    400: `#98BFCA`,
    500: `#84B2BF`,
    600: `#70A5B5`,
    700: `#5B99AA`,
    800: `#478CA0`,
    900: `#2D7386`,
    1000: `#19434F`,
  },
  olive: {
    25: `#F8F8F4`,
    50: `#F2F2EC`,
    100: `#E4E4D9`,
    200: `#D7D7C5`,
    300: `#CAC9B2`,
    400: `#BCBB9F`,
    500: `#AFAE8C`,
    600: `#A2A179`,
    700: `#959365`,
    800: `#878552`,
    900: `#53532D`,
    1000: `#3C3C1D`,
  },
  plum: {
    25: `#FBF7FA`,
    50: `#F5ECF4`,
    100: `#EBD8EA`,
    200: `#E2C5DF`,
    300: `#D8B1D4`,
    400: `#CE9EC9`,
    500: `#C48BBF`,
    600: `#BA77B4`,
    700: `#B164A9`,
    800: `#A7509F`,
    900: `#9D3D94`,
    1000: `#60205A`,
  },
  pewter: {
    50: `#EEF2F3`,
    100: `#DDE4E7`,
    200: `#CDD7DB`,
    300: `#BCC9CF`,
    400: `#ABBBC3`,
    500: `#9AAEB7`,
    600: `#89A1AB`,
    700: `#79939F`,
    800: `#688593`,
    900: `#577887`,
  },
  /**
   * greys @deprecated
   */
  salmonPink: {
    500: `#F15F61`,
  },
  sky: {
    50: `#F5FBFD`,
    100: `#E7F5FA`,
    200: `#D6F0F9`,
    // Temporary Fix while updating components
    // Switch defaults to <color>.500 and requires
    // customization to use other color variants
    500: `#2191B7`,
    600: `#2191B7`,
    800: `#006080`,
  },
  emerald: {
    100: `#F0FBF6`,
    600: `#25B579`,
  },
  "deprecated-olive-900": `#7A783F`,
  active: `#00924F`,
  disabled: `#C0C0C0`, // grey.200
  disabled_dark: `#969696`, // grey.400
  pending: `#FF9431`,
  archived: `#DD0000`,
  preqin: `#5F48B6`,
  listing_type: `#2191B7`,
  inactive: `#2191B7`,
};

export default colors;
