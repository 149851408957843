import { FeatureFlags } from "@/modules/LaunchDarkly";
import { useSession } from "@/modules/Session";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import { useEffect, useMemo } from "react";

interface UseFeatureFlags extends FeatureFlags {
  loading?: boolean;
}

const useFeatureFlags = (): UseFeatureFlags => {
  const { user } = useSession();
  const userId = user?.id;

  const ldClient = useLDClient();
  const flags = useFlags(); // subscribe to flag changes

  const allFlags = useMemo(
    () => (ldClient?.allFlags() || { loading: true }) as UseFeatureFlags,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ldClient, flags],
  );

  useEffect(() => {
    if (!ldClient || !userId) return;
    if (process.env.NEXT_PUBLIC_E2E) {
      if (ldClient)
        ldClient.identify({
          key: `e2e2e2e2-e2e2-e2e2-e2e2-e2e2e2e2e2e2`,
          anonymous: false,
        });
    } else {
      ldClient.identify({ key: userId, anonymous: false });
    }
  }, [userId, ldClient]);

  return allFlags;
};

export default useFeatureFlags;
