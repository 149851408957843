import { ForwardedRef, forwardRef } from "react";
import { Input, InputProps as ChakraInputProps } from "@chakra-ui/react";
import { useHookFormMask } from "use-mask-input";
import InputWrapper, { InputWrapperProps } from "./InputWrapper";

type PhoneInputProps = InputWrapperProps &
  ChakraInputProps &
  ReturnType<ReturnType<typeof useHookFormMask>>;

const PhoneInput = forwardRef<typeof Input, PhoneInputProps>(
  (
    { type: _, name, ...inputProps }: PhoneInputProps,
    ref: ForwardedRef<typeof Input>,
  ) => (
    <InputWrapper name={name} {...inputProps}>
      <Input id={name} name={name} type="phone" {...inputProps} ref={ref} />
    </InputWrapper>
  ),
);

export default PhoneInput;
