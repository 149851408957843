import { menuAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys);

const baseStyle = definePartsStyle({
  button: {
    bg: `white`,
    borderRadius: `md`,
    borderWidth: `1px`,
    color: `grey.900`,
    px: `11px`,
    py: `7px`,
    _hover: {
      bg: `grey.50`,
    },
    borderColor: `grey.100`,
  },
  list: {
    p: 0,
    bg: `white`,
    borderRadius: `6px`,
    boxShadow: `card`,
    borderWidth: `0.5px`,
    borderColor: `grey.200`,
  },
  item: {
    borderBottomWidth: `0.5px`,
    borderColor: `grey.50`,
    fontSize: `0.875rem`,
    fontWeight: 500,
    py: `0.5rem`,
    _focus: {
      bg: `grey.50`,
    },
    _last: {
      borderBottomWidth: 0,
    },
    bg: `none`,
  },
  divider: {
    borderColor: `grey.50`,
    borderWidth: `4px`,
    m: 0,
  },
});

const Menu = defineMultiStyleConfig({ baseStyle });

export default Menu;
