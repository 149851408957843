import { UseControllerProps, useController } from "react-hook-form";

export const useComboboxItemField = <TItem>(props: UseControllerProps) => {
  const {
    field: { value: selectedItem, name },
    fieldState: { isTouched, error },
  } = useController(props);

  return {
    selectedItem: selectedItem as TItem,
    isInvalid: (error && isTouched) || false,
    error,
    name,
  };
};
