const sizes = {
  "22": `5.5rem`,
  "71": `17.75rem`,
  "74": `18.5rem`,
  "76": `19rem`,
  "88": `22rem`,
  "96": `24rem`,
  "112": `28rem`,
  "120": `30rem`,
  "138": `34.5rem`,
  "144": `36rem`,
  "160": `40rem`,
  "178": `44.5rem`,
  "max-width-md": `1280px`,
  "max-width-lg": `1560px`,
  "max-width-xl": `1792px`,
  "mobile-header-height": `70px`,
  "desktop-header-height": `80px`,
  "mobile-navbar-height": `72px`,
  "desktop-navbar-height": `88px`,
  "desktop-admin-navbar-height": `64px`,
  "mobile-tab-list-footer-height": `60px`,
  "desktop-full-height-minus-header": `calc(100vh - var(--chakra-sizes-desktop-header-height))`,
  "desktop-full-height-minus-navbar": `calc(100vh - var(--chakra-sizes-desktop-navbar-height))`,
};

export default sizes;
