const fontSizes = {
  "3xs": `0.75rem`,
  "2xs": `0.813rem`,
  xs: `0.875rem`,
  base: `1rem`,
  lg: `1.125rem`,
  xl: `1.25rem`,
  "2xl": `1.375rem`,
  "3xl": `1.5rem`,
  "4xl": `2rem`,
};

export default fontSizes;
