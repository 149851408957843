import { ForwardedRef, forwardRef } from "react";

import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";

import GenericInput from "./GenericInput";
import { InputProps } from "./Input";

type NumberInputType = InputProps & { precision?: number };

function checkNumberFormat(
  e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  precision?: number,
) {
  if (!Number.isInteger(precision)) {
    return;
  }

  const inputValue = e.currentTarget.value;
  const isValueSelectedToReplace =
    inputValue && document?.getSelection()?.toString() === inputValue;
  const isNumberKey = /^[\d.e]$/.test(e.key);

  if (isNumberKey && isValueSelectedToReplace) {
    return;
  }

  const isPrecisionValid = new RegExp(
    `^\\d+${precision ? `(\\.\\d{0,${precision}})?` : ``}$`,
  ).test(inputValue + e.key);

  if (isNumberKey && !isPrecisionValid) {
    e.preventDefault();
  }
}

const NumberInput = forwardRef<typeof Input, NumberInputType>(
  (
    { type: _, iconLeft, label, precision, ...inputProps }: NumberInputType,
    ref: ForwardedRef<typeof Input>,
  ) => (
    <InputGroup w="full">
      {iconLeft && (
        <InputLeftElement mt={label ? `27px` : 0}>{iconLeft}</InputLeftElement>
      )}
      <GenericInput
        type="number"
        step=".01"
        onWheel={(e) => e.currentTarget.blur()}
        label={label}
        pl={iconLeft ? 10 : undefined}
        {...inputProps}
        ref={ref}
        onKeyDown={(e) => checkNumberFormat(e, precision)}
      />
    </InputGroup>
  ),
);

export default NumberInput;
