import { defineStyleConfig } from "@chakra-ui/react";

const Badge = defineStyleConfig({
  baseStyle: {
    alignItems: `center`,
    display: `flex`,
    gap: 1,
  },
  variants: {
    italic: {
      bg: `transparent`,
      fontWeight: 400,
      fontStyle: `italic`,
      textTransform: `capitalize`,
    },
    plain: {
      bg: `transparent`,
      fontWeight: 400,
      textTransform: `capitalize`,
    },
    active: {
      bg: `emerald.100`,
      color: `emerald.600`,
      fontWeight: `normal`,
      borderRadius: `md`,
      textTransform: `initial`,
      textStyle: `colfax-12-normal`,
    },
    inactive: {
      bg: `grey.50`,
      color: `grey.100`,
      fontWeight: `normal`,
      borderRadius: `md`,
      textTransform: `initial`,
      textStyle: `colfax-12-normal`,
      textColor: `grey.900`,
    },
    statusPending: {
      bg: `skyBlue.100`,
      fontWeight: `normal`,
      borderRadius: `md`,
      textTransform: `initial`,
      textStyle: `colfax-12-normal`,
      textColor: `skyBlue.600`,
    },
  },
});

export default Badge;
