import { BackButton } from "@/modules/Navigation";
import { Box } from "@chakra-ui/react";
import { ReactNode } from "react";

export default function ErrorContainer({ children }: { children: ReactNode }) {
  return (
    <Box bg="grey.25">
      <Box px={4} py={6}>
        <BackButton />
      </Box>
      <Box
        px={{ base: 4, md: 8 }}
        py={{ base: 10, md: 20 }}
        minH="100vh"
        width="100%"
      >
        {children}
      </Box>
    </Box>
  );
}
