import { LDProvider, ProviderConfig } from "launchdarkly-react-client-sdk";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { featureFlags } from "@/modules/LaunchDarkly";

export default function LaunchDarklyProvider({
  children,
}: {
  children: ReactNode;
}) {
  const offline = useMemo(
    () => !process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_SIDE_ID,
    [],
  );

  const [options, setOptions] = useState<ProviderConfig>({
    clientSideID: process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_SIDE_ID,
    flags: featureFlags,
  });

  useEffect(() => {
    if (!offline) {
      setOptions({
        clientSideID: process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_SIDE_ID,
        context: process.env.NEXT_PUBLIC_E2E
          ? {
              key: `e2e2e2e2-e2e2-e2e2-e2e2-e2e2e2e2e2ea`,
              anonymous: false,
            }
          : {
              kind: `user`,
              anonymous: true,
            },
        options: {
          bootstrap: `localStorage`,
          streaming: true,
        },
        reactOptions: {
          useCamelCaseFlagKeys: true,
        },
      });
    }
  }, [offline]);

  return <LDProvider {...options}>{children}</LDProvider>;
}
