import { checkboxAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const Checkbox = defineMultiStyleConfig({
  defaultProps: {
    colorScheme: `whiteAlpha`,
    size: `lg`,
  },
  baseStyle: definePartsStyle({
    icon: {
      color: `grey.900`,
      strokeLinecap: `round`,
      strokeWidth: `2.5 !important`,
      fontSize: `0.870rem`,
    },
    container: {
      _disabled: {
        color: `grey.500`,
        opacity: 0.5,
        fontSize: `0.870rem`,
        fontWeight: `400`,
        cursor: `not-allowed`,
      },
      _invalid: {
        color: `archived`,
        fontWeight: `light`,
      },
    },
    label: {
      _invalid: {
        color: `archived`,
        fontWeight: `400`,
      },
    },
    control: {
      padding: 2,
      borderRadius: 4,
      bg: `white`,
      borderColor: `grey.600`,
      borderWidth: `1px`,
      _focus: {
        boxShadow: `focus`,
      },
      _invalid: {
        borderColor: `archived`,
      },
    },
  }),
  sizes: {
    lg: definePartsStyle({
      control: defineStyle({
        boxSize: 5,
      }),
      label: defineStyle({
        marginLeft: 2,
        fontSize: 16,
      }),
    }),
  },
});

export default Checkbox;
