import { resetAuth } from "@/state/auth";
import { useLoginActions } from "@frontegg/nextjs";
import * as Sentry from "@sentry/nextjs";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

export function useSignOut() {
  const dispatch = useDispatch();
  const { logout } = useLoginActions();

  return useCallback(() => {
    Sentry.setUser(null);
    dispatch(resetAuth());
    logout();
  }, [dispatch, logout]);
}
