import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  overlay: {
    bg: `rgba(0, 0, 0, 0.45)`,
  },
  dialog: {
    bg: `white`,
    borderColor: `grey.200`,
    borderRadius: `md`,
    borderWidth: 0.5,
    boxShadow: `card`,
    mx: { base: 0, md: `auto` },
    my: { base: 0, md: `auto` },
  },
  dialogContainer: {
    overflow: `auto`,
  },
  header: {
    fontSize: `xl`,
    fontWeight: 500,
    px: `2.5rem`,
    pt: `2rem`,
    pb: 0,
  },
  body: {
    px: `2.5rem`,
    pt: `1rem`,
    pb: `2.5rem`,
  },
  footer: {
    borderColor: `grey.200`,
    borderTopWidth: 0.5,
    px: `1.5rem`,
    py: `1rem`,
    gap: 3,
  },
  closeButton: {
    borderColor: `grey.100`,
    borderRadius: `md`,
    borderWidth: 1,
    padding: 0,
    position: `absolute`,
    right: `1rem`,
    top: `1rem`,
    w: `24px`,
    h: `24px`,
    svg: {
      w: `10px`,
      h: `10px`,
    },
  },
});

const skinny = definePartsStyle({
  ...baseStyle,
  header: {
    fontSize: `xl`,
    fontWeight: 500,
    pt: `2rem`,
    pb: 0,
    px: `1.5rem`,
  },
  body: {
    px: `1.5rem`,
    pt: `1rem`,
    pb: `2.5rem`,
  },
});

const Modal = defineMultiStyleConfig({
  defaultProps: {
    variant: `base`,
  },
  variants: {
    base: {
      ...baseStyle,
    },
    skinny,
  },
});

export default Modal;
