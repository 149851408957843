import { ForwardedRef, forwardRef } from "react";
import { Input, InputProps as ChakraInputProps } from "@chakra-ui/react";
import { useHookFormMask } from "use-mask-input";
import { FieldError } from "react-hook-form";
import InputWrapper from "./InputWrapper";

type Props = ChakraInputProps & ReturnType<ReturnType<typeof useHookFormMask>>;
interface EmailInputProps extends Props {
  label?: string;
  error?: FieldError;
}

const EmailInput = forwardRef<typeof Input, EmailInputProps>(
  (
    { type: _, name, ...inputProps }: EmailInputProps,
    ref: ForwardedRef<typeof Input>,
  ) => (
    <InputWrapper name={name} {...inputProps}>
      <Input id={name} name={name} type="email" {...inputProps} ref={ref} />
    </InputWrapper>
  ),
);

export default EmailInput;
