import { defineStyleConfig } from "@chakra-ui/react";

const Popover = defineStyleConfig({
  baseStyle: {
    content: {
      mx: 4,
      bg: `white`,
      borderColor: `grey.200`,
      borderWidth: `1px`,
      borderStyle: `solid`,
    },
  },
});

export default Popover;
