import { Box, BoxProps, keyframes } from "@chakra-ui/react";

import { useColors } from "@/modules/Theme";

export interface SkeletonProps extends BoxProps {
  /** The height of the skeleton element(s). */
  readonly h: BoxProps["h"];
  /** The number of skeleton elements to render. */
  readonly count?: number;
}

const animationKeyframes = keyframes`
  0% { background-position: -200px }
  100% { background-position: 800px }`;

function Skeleton({ count = 1, w = `full`, ...skeletonProps }: SkeletonProps) {
  const [grey75] = useColors([`grey.50`]);
  const [white] = useColors([`white`]);

  return (
    <>
      {[...Array(count).keys()].map((index) => (
        <Box
          borderRadius="md"
          borderColor="grey.75"
          borderWidth={2}
          bgImage={`linear-gradient(90deg, ${grey75} 0px, ${white} 100px, ${grey75} 200px)`}
          bgSize="1000px"
          key={index}
          border="none"
          w={w}
          {...skeletonProps}
          animation={`${animationKeyframes} 1.5s linear infinite`}
        />
      ))}
    </>
  );
}

export default Skeleton;
