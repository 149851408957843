import { ROUTES } from "@/constants";
import { UserMultiFactorAuthenticationStatus } from "@/graphql";
import { useBackOfficeConfiguration } from "@/modules/LaunchDarkly";
import { useSession, useSignOut } from "@/modules/Session";
import { useAuthUserOrNull } from "@frontegg/nextjs";
import { useRouter } from "next/router";
import { useEffect } from "react";

export default function useRouterRedirectEffect() {
  const { replace, pathname } = useRouter();
  const signOut = useSignOut();
  const fronteggUser = useAuthUserOrNull();
  const backOfficeConfigurationEnabled = useBackOfficeConfiguration();
  const { user, isAuthenticated } = useSession();

  const isDefaultLogin = pathname === ROUTES.DEFAULT_LOGIN;
  const isFronteggLogin = pathname === ROUTES.FRONTEGG_LOGIN;
  const isFronteggLoginCallbackRoute = pathname.startsWith(ROUTES.CALLBACK);

  const hasUser = !!user;
  const hasFronteggUser = !!fronteggUser;

  const isPublicRoute =
    isDefaultLogin || isFronteggLogin || isFronteggLoginCallbackRoute;

  const hasAppMfa =
    user?.multiFactorAuthentications.app ===
    UserMultiFactorAuthenticationStatus.Active;

  const shouldEnforceMfa =
    backOfficeConfigurationEnabled && hasFronteggUser && hasUser && !hasAppMfa;

  const shouldForceSignOut =
    backOfficeConfigurationEnabled && !isPublicRoute && !hasFronteggUser;

  useEffect(() => {
    if (shouldEnforceMfa && pathname !== ROUTES.SETUP_MFA) {
      replace(ROUTES.SETUP_MFA);
      return;
    }

    if (!isAuthenticated && !isPublicRoute) {
      replace(ROUTES.DEFAULT_LOGIN);
      return;
    }

    if (isAuthenticated && (isDefaultLogin || isFronteggLoginCallbackRoute)) {
      replace(ROUTES.USERS);
      return;
    }

    if (shouldForceSignOut) {
      signOut();
    }
  }, [
    isAuthenticated,
    isPublicRoute,
    replace,
    shouldEnforceMfa,
    shouldForceSignOut,
    signOut,
    pathname,
    isDefaultLogin,
    isFronteggLoginCallbackRoute,
  ]);
}
