import { defineStyleConfig } from "@chakra-ui/react";

const Status = defineStyleConfig({
  variants: {
    "yellow-bordered": {
      bg: `yellow.50`,
      borderColor: `pending`,
      borderWidth: 1,
      borderRadius: 6,
      color: `pending`,
    },
    "sky-bordered": {
      bg: `sky.100`,
      borderColor: `sky.500`,
      borderWidth: 1,
      borderRadius: 6,
      color: `sky.500`,
    },
    "red-bordered": {
      bg: `red.50`,
      borderColor: `archived`,
      borderWidth: 1,
      borderRadius: 6,
      color: `archived`,
    },
    "green-bordered": {
      bg: `green.50`,
      borderColor: `active`,
      borderWidth: 1,
      borderRadius: 6,
      color: `active`,
    },
    "purple-white": {
      color: `purple.500`,
    },
    yellow: {
      bg: `yellow.50`,
      borderRadius: 6,
      color: `pending`,
    },
    sky: {
      bg: `sky.100`,
      borderRadius: 6,
      color: `sky.500`,
    },
    red: {
      bg: `red.50`,
      borderRadius: 6,
      color: `archived`,
    },
    green: {
      bg: `green.50`,
      borderRadius: 6,
      color: `active`,
    },
    grey: {
      bg: `grey.50`,
      borderRadius: 6,
      color: `grey.900`,
    },
    olive: {
      bg: `olive.25`,
      borderColor: `olive.700`,
      borderWidth: 1,
      borderRadius: 6,
      color: `olive.700`,
    },
    plum: {
      bg: `plum.25`,
      borderColor: `plum.700`,
      borderWidth: 1,
      borderRadius: 6,
      color: `plum.700`,
    },
    outline: {
      bg: `grey.25`,
      borderColor: `grey.100`,
      borderWidth: 1,
      borderRadius: 6,
      color: `grey.900`,
    },
  },
});

export default Status;
