import random from "lodash/random";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { HiiveFullLogo } from "@/modules/Assets";

const WINNING_NUMBER = 1;

function PageHeader() {
  const randomNumber = useMemo(() => random(10, false), []);
  const { t } = useTranslation();

  const baseStyles = {
    fontFamily: `var(--chakra-fonts-body)`,
    margin: `0 auto`,
  };

  return (
    <div style={baseStyles}>
      <div style={{ margin: `0 auto` }}>
        {randomNumber === WINNING_NUMBER ? (
          <HiiveFullLogo.DarkGreyWithBee />
        ) : (
          <HiiveFullLogo.DarkGreyWithPink />
        )}
      </div>

      <h1
        style={{
          fontSize: `22px`,
          fontWeight: 500,
          marginTop: `3.5rem`,
          marginBottom: `1.75rem`,
          textAlign: `center`,
        }}
      >
        {t(`sign_in`)}
      </h1>
    </div>
  );
}

export default PageHeader;
