import {
  CurrentActorDocument,
  CurrentActorFragment,
  CurrentActorQuery,
  CurrentActorQueryVariables,
} from "@/graphql";
import { setupClient } from "@/modules/Urql";
import { createClient } from "@urql/core";

import { useEffect, useState } from "react";

interface useCurrentUserProps {
  token?: string | null;
}

const getCurrentUser = async (token?: string | null) => {
  const clientOptions = setupClient({
    isAuthenticated: false,
    token,
  });

  const client = createClient(clientOptions);

  const { data } = await client.query<
    CurrentActorQuery,
    CurrentActorQueryVariables
  >(CurrentActorDocument, {});

  return data?.currentActor;
};

export function useCurrentUser({ token }: useCurrentUserProps) {
  const [currentUser, setCurrentUser] = useState<CurrentActorFragment | null>();
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    if (token) {
      (async () => {
        setFetching(true);
        const result = await getCurrentUser(token);
        setCurrentUser(result);
        setFetching(false);
      })();
    }
  }, [token]);

  return [{ fetching, data: currentUser }];
}
