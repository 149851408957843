import { Link } from "@/modules/Navigation";
import { Text, VStack } from "@chakra-ui/react";

interface TopMenuItemProps {
  href: string;
  icon: React.ReactNode;
  isActive?: boolean;
  label: string;
  target?: string;
  disabled?: boolean;
}

export function TopMenuItem({
  href,
  icon,
  isActive,
  label,
  target,
  disabled = false,
}: TopMenuItemProps) {
  return (
    <Link href={href} target={target} disabled={disabled}>
      <VStack
        alignItems="center"
        bg={isActive ? `grey.900` : `transparent`}
        borderColor="grey.600"
        borderRadius={8}
        borderWidth={isActive ? 0.5 : 0}
        color="white"
        gap={1}
        px={4}
        py={2}
      >
        {icon}
        <Text textStyle="colfax-14-regular">{label}</Text>
      </VStack>
    </Link>
  );
}
