import { DefinitionNode, GraphQLError } from "graphql";
import { GetServerSidePropsContext } from "next";

import { GQL_OPERATION_NAMES } from "@/constants";
import { ToastDuration } from "@/modules/Toast";
import { createStandaloneToast } from "@chakra-ui/react";
import * as Sentry from "@sentry/nextjs";
import {
  cacheExchange,
  ClientOptions,
  fetchExchange,
  mapExchange,
  ssrExchange,
} from "@urql/core";
import { authExchange } from "@urql/exchange-auth";

const AUTH_ERROR_CODES = [`FORBIDDEN`, `UNAUTHENTICATED`];

const PUBLIC_OPERATIONS = [GQL_OPERATION_NAMES.authLogin];

const { toast } = createStandaloneToast();

type SetupClientParams = {
  context?: GetServerSidePropsContext;
  isAuthenticated: boolean;
  isClientSsrExchange?: boolean;
  token?: string | null;
  onAuthError?: () => void;
  url?: string | null;
} & Omit<ClientOptions, "url" | "exchanges" | "suspense">;

type OriginalError = GraphQLError["originalError"] & { code?: string };

type DefinitionNodeType = DefinitionNode & {
  name: { kind: string; value: string };
};

const captureSentryErrors = async (errors: GraphQLError[]) => {
  errors.forEach((error) => {
    Sentry.captureMessage(`[ GraphQLError ] ${error.message}`, {
      extra: { error },
    });
    toast({
      title: `GraphQLError`,
      description: error.message,
      status: `error`,
      isClosable: true,
      duration: ToastDuration.Long,
    });
  });

  await Sentry.flush(2000);
};

export const setupClient = ({
  isAuthenticated,
  isClientSsrExchange = false,
  token,
  onAuthError,
  url,
  ...rest
}: SetupClientParams) => {
  const defaultParams: ClientOptions = {
    url:
      url ||
      process.env.NEXT_PUBLIC_API_URL ||
      `http://localhost:4000/graphql/back-office/`,
    exchanges: [
      cacheExchange,
      authExchange(async (utils) => ({
        addAuthToOperation(operation) {
          if (!token) {
            return operation;
          }
          return utils.appendHeaders(operation, {
            Authorization: `Bearer ${token}`,
          });
        },
        willAuthError(operation) {
          const isAuth = operation.query.definitions.some((definition) => {
            const operationName = (definition as DefinitionNodeType).name
              ?.value;

            return PUBLIC_OPERATIONS.includes(operationName);
          });

          return !token && !isAuth;
        },
        didAuthError(error) {
          return error.graphQLErrors.some(({ extensions, originalError }) =>
            [extensions?.code, (originalError as OriginalError)?.code].some(
              (code) => AUTH_ERROR_CODES.includes(code as string),
            ),
          );
        },
        async refreshAuth() {
          onAuthError?.();
        },
      })),
      mapExchange({
        onOperation(operation) {
          const { variables } = operation;

          if (variables?.filterBy?.searchText) {
            const searchText = variables?.filterBy?.searchText;
            const newSearchText = searchText.replace(/\\/g, `\\\\`);

            return {
              ...operation,
              variables: {
                ...variables,
                filterBy: {
                  ...variables.filterBy,
                  searchText: newSearchText,
                },
              },
            };
          }

          return operation;
        },
        onResult({ error }) {
          if (error?.networkError) {
            Sentry.captureException(error.networkError);
          }

          if (isAuthenticated && error?.graphQLErrors) {
            captureSentryErrors(error.graphQLErrors);
          }
        },
      }),
      fetchExchange,
      ssrExchange({ isClient: isClientSsrExchange }),
    ],
    ...rest,
  };

  return defaultParams;
};
