export default {
  account: `account`,
  activate_user: `Activate User`,
  add_transaction: `Add Transaction`,
  approve_user: `Approve User`,
  archive_user: `Archive User`,
  confirm_activate_title: `Confirm activate user`,
  confirm_approve_user_title: `Confirm approve user`,
  confirm_archive_title: `Confirm archive user`,
  confirm_deactivate_title: `Confirm deactivate user`,
  confirm_deactivate_textarea_helper_text: `Only visible to Hiive Admin`,
  confirm_deactivate_textarea_label: `Comment/Deactivation reason`,
  confirm_ignore_title: `Confirm ignore user`,
  confirm_remove_ignore_title: `Confirm remove ignore`,
  deactivation_reason: `Deactivation Reason`,
  deactivate_user: `Deactivate User`,
  ignore_user: `Ignore User`,
  membership_agreement_url: `Membership Agreement URL`,
  needs_to_be_manually_verified: `Needs to be manually verified.`,
  no_transactions: `No Transactions`,
  open_in_persona: `Open in Persona`,
  remove_ignore: `Remove Ignore`,
  user_page_head_text: `User | Hiive BO`,
  user_documents: `User Documents`,
  view_transactions: `View Transactions`,
  verification_cancelled_explainer: `The verification process was cancelled.`,
  verification_failed_explainer: `The verification failed`,
};
