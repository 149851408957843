import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const ghost = defineStyle({
  bg: `transparent`,
  borderColor: `transparent`,
  _hover: {
    bg: `grey.50`,
  },
  _active: {
    borderColor: `black`,
    borderWidth: 1,
    borderRadius: `md`,
  },
});

const outline = defineStyle({
  bg: `white`,
  borderColor: `grey.200`,
  borderRadius: `md`,
  borderWidth: 1,
  _hover: {
    bg: `grey.50`,
  },
});

const destructive = defineStyle({
  bg: `white`,
  borderColor: `archived`,
  color: `archived`,
  borderRadius: `md`,
  borderWidth: 1,
  _hover: {
    bg: `grey.50`,
  },
});

const solid = defineStyle({
  bg: `grey.800`,
  borderRadius: `md`,
  color: `white`,
  _hover: {
    bg: `grey.600`,
  },
});

const hiive = defineStyle({
  color: `white`,
  bg: `salmon.900`,
  borderRadius: `50px`,
  _loading: {
    pointerEvents: `none`,
    opacity: `.7`,
    _hover: {
      pointerEvents: `none`,
      opacity: `.7`,
    },
  },
  _hover: {
    bg: `red.600`,
  },
  _focus: {
    boxShadow: `0px 0px 5px 0.5px var(--chakra-colors-skyBlue-900)`,
  },
});

const link = defineStyle({
  color: `grey.900`,
  fontSize: `0.875rem`,
  lineHeight: `1.5rem`,
  minWidth: `auto`,
  textDecoration: `underline`,
  _active: {
    border: `none`,
  },
  _hover: {
    color: `grey.600`,
  },
  _focus: {
    boxShadow: `none`,
  },
});

const unselected = defineStyle({
  bg: `grey.50`,
  borderColor: `transparent`,
  _hover: {
    bg: `grey.100`,
  },
  _active: {
    borderColor: `black`,
    borderWidth: 1,
    borderRadius: `md`,
  },
});

const selected = defineStyle({
  bg: `grey.800`,
  borderRadius: `md`,
  color: `white`,
  _hover: {
    bg: `grey.600`,
  },
});

const iconClose = defineStyle({
  minW: 10,
  minH: 10,
  h: `unset`,
  bg: `transparent`,
  _hover: {
    bg: `transparent`,
    color: `grey.50`,
  },
  _active: {
    bg: `transparent`,
    color: `grey.200`,
  },
});

const Button = defineStyleConfig({
  baseStyle: {
    fontWeight: 500,
    _disabled: {
      pointerEvents: `none`,
    },
    _focus: {
      boxShadow: `focus`,
    },
    _active: {
      borderColor: `grey.900`,
    },
  },
  sizes: {
    xs: {
      fontSize: `0.75rem`,
      padding: `0.5rem`,
    },
    sm: {
      fontSize: `0.75rem`,
      lineHeight: `0.5rem`,
      px: `1rem`,
      py: `0.75rem`,
    },
    md: {
      fontSize: `0.75rem`,
      lineHeight: `1rem`,
      px: `1rem`,
      py: `0.75rem`,
    },
    lg: {
      fontSize: `1rem`,
      lineHeight: `0.75rem`,
      padding: `1rem`,
      h: `44px`,
    },
  },
  variants: {
    ghost,
    destructive,
    outline,
    solid,
    hiive,
    link,
    unselected,
    selected,
    iconClose,
  },
});

export default Button;
