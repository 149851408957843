import {
  InputGroup,
  InputRightElement,
  useDisclosure,
  IconButton,
  Input,
} from "@chakra-ui/react";
import { Eye, EyeSlash } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";
import { ForwardedRef, forwardRef } from "react";
import { InputProps } from "./Input";
import InputWrapper from "./InputWrapper";

const PasswordInput = forwardRef<typeof Input, InputProps>(
  ({ name, ...inputProps }: InputProps, ref: ForwardedRef<typeof Input>) => {
    const { t } = useTranslation();
    const { isOpen, onToggle } = useDisclosure();

    return (
      <InputWrapper name={name} {...inputProps}>
        <InputGroup>
          <InputRightElement>
            <IconButton
              minW={10}
              maxW={10}
              mr={2.5}
              tabIndex={-1}
              _focus={{
                outline: `none`,
              }}
              bg="transparent !important"
              variant="ghost"
              aria-label={isOpen ? t(`mask_password`) : t(`reveal_password`)}
              icon={
                isOpen ? (
                  <EyeSlash color="#403F41" size={32} />
                ) : (
                  <Eye color="#403F41" size={32} />
                )
              }
              onClick={onToggle}
            />
          </InputRightElement>
          <Input
            type={isOpen ? `text` : `password`}
            autoComplete="current-password"
            name={name}
            {...inputProps}
            ref={ref}
          />
        </InputGroup>
      </InputWrapper>
    );
  },
);

export default PasswordInput;
