import { ReactNode } from "react";
import { UseFormRegisterReturn } from "react-hook-form";

import {
  InputProps as ChakraInputProps,
  TextareaProps,
} from "@chakra-ui/react";

import AreaInput from "./AreaInput";
import DateInput from "./DateInput";
import DecimalInput from "./DecimalInput";
import EmailInput from "./EmailInput";
import DecimalInputWithSeparator from "./DecimalInputWithSeparator";
import GenericInput from "./GenericInput";
import { InputWrapperProps } from "./InputWrapper";
import MoneyInput from "./MoneyInput";
import NumberInput from "./NumberInput";
import PasswordInput from "./PasswordInput";
import PhoneInput from "./PhoneInput";
import SearchInput from "./SearchInput";
import SelectInput from "./SelectInput";
import SwitchInput from "./SwitchInput";
import TimeInput from "./TimeInput";

export interface InputIconProps {
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
}

export type InputProps = InputWrapperProps &
  ChakraInputProps &
  TextareaProps &
  UseFormRegisterReturn &
  InputIconProps;

const Input = {
  Area: AreaInput,
  Date: DateInput,
  Decimal: DecimalInput,
  DecimalWithSeparator: DecimalInputWithSeparator,
  Email: EmailInput,
  Generic: GenericInput,
  Money: MoneyInput,
  Number: NumberInput,
  Password: PasswordInput,
  Phone: PhoneInput,
  Search: SearchInput,
  Select: SelectInput,
  Switch: SwitchInput,
  Time: TimeInput,
};

export default Input;
