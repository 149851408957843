import { tagAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tagAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    borderWidth: `1px`,
    maxWidth: `fit-content`,
    padding: `0.5rem`,
  },
});

const blue = definePartsStyle({
  container: {
    borderColor: `sky.600`,
    borderRadius: `md`,
  },
  label: {
    color: `sky.800`,
  },
  closeButton: {
    color: `sky.800`,
  },
});

const subtle = definePartsStyle({
  container: {
    borderRadius: `sm`,
    padding: `1px 4px`,
    backgroundColor: `#f0f0f0`,
    border: `0px`,
  },
  label: {
    color: `grey.800`,
  },
});

const grey = definePartsStyle({
  container: {
    borderRadius: `md`,
    padding: `2px 6px`,
    backgroundColor: `grey.50`,
    border: `0px`,
    fontWeight: `400`,
  },
  label: {
    color: `grey.900`,
  },
});

const lightBlue = definePartsStyle({
  container: {
    borderRadius: `md`,
    padding: `2px 6px`,
    backgroundColor: `sky.100`,
    border: `0px`,
    fontWeight: `400`,
  },
  label: {
    color: `grey.900`,
  },
});

const Tag = defineMultiStyleConfig({
  baseStyle,
  variants: { blue, lightBlue, subtle, grey },
});

export default Tag;
