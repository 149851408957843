import { useBackOfficeSessionEnabled } from "@/modules/LaunchDarkly";
import { useSession, useSignOut, useTokenExchange } from "@/modules/Session";
import { useAuthUserOrNull } from "@frontegg/nextjs";
import { useCallback, useEffect } from "react";

export default function useAuthRedirectionEffect() {
  const { isAuthenticated: hasSessionToken } = useSession();
  const signOut = useSignOut();
  const fronteggUser = useAuthUserOrNull();
  const backOfficeSessionEnabled = useBackOfficeSessionEnabled();

  const { fetching: fetchingSessionToken, exchangeToken } = useTokenExchange({
    onError: signOut,
  });

  const isLoading = hasSessionToken || fetchingSessionToken;
  const shouldExchangeToken =
    fronteggUser && !hasSessionToken && !fetchingSessionToken;

  const maybeExchangeToken = useCallback(async () => {
    if (shouldExchangeToken) {
      await exchangeToken(fronteggUser.accessToken);
    }
  }, [exchangeToken, fronteggUser?.accessToken, shouldExchangeToken]);

  useEffect(() => {
    if (!backOfficeSessionEnabled) {
      maybeExchangeToken();
    }
  }, [
    backOfficeSessionEnabled,
    maybeExchangeToken,
    exchangeToken,
    fronteggUser?.accessToken,
    shouldExchangeToken,
  ]);

  return { isLoading };
}
