import {
  FormLabel,
  Input,
  InputGroup,
  VStack,
  InputRightElement,
  FormControl,
  FormErrorMessage,
  Box,
  Button,
  ButtonGroup,
  HStack,
  Select,
  Text,
} from "@chakra-ui/react";

import { forwardRef, LegacyRef } from "react";
import ReactDatePicker from "react-datepicker";
import { DateTime } from "luxon";
import { CalendarBlank } from "@phosphor-icons/react";
import { ThemingProps } from "@chakra-ui/styled-system";
import styles from "./DatePicker.module.css";

function TimePicker({
  dateTime,
  setDateTime,
  buttonGroupSize = `lg`,
}: {
  dateTime: DateTime;
  setDateTime: (dateTime: DateTime) => void;
  buttonGroupSize?: ThemingProps<"Button">["size"];
}) {
  const hours = dateTime.hour % 12 || 12;
  const minutes = dateTime.minute;
  const am = dateTime.hour < 12;
  return (
    <HStack w="fit-content" py={2}>
      <Select
        w={70}
        value={hours}
        onChange={(e) => {
          const newHour = am
            ? parseInt(e.target.value, 10) % 12
            : (parseInt(e.target.value, 10) % 12) + 12;
          setDateTime(dateTime.set({ hour: newHour }));
        }}
      >
        {Array.from({ length: 12 }, (_, hour) => hour + 1).map((hour) => (
          <option key={hour} value={hour}>
            {hour}
          </option>
        ))}
      </Select>
      <Text textStyle="colfax-14-medium-uppercased">:</Text>
      <Select
        w={70}
        value={minutes}
        onChange={(e) => {
          const newMinute = parseInt(e.target.value, 10);
          setDateTime(dateTime.set({ minute: newMinute }));
        }}
      >
        {Array.from({ length: 60 }, (_, minute) => minute).map((minute) => (
          <option key={minute} value={minute}>
            {minute < 10 ? `0${minute}` : minute}
          </option>
        ))}
      </Select>
      <ButtonGroup size={buttonGroupSize} isAttached variant="outline">
        <Button
          onClick={() => {
            if (!am) {
              setDateTime(dateTime.set({ hour: dateTime.hour - 12 }));
            }
          }}
          variant={!am ? `unselected` : `selected`}
        >
          AM
        </Button>
        <Button
          onClick={() => {
            if (am) {
              setDateTime(dateTime.set({ hour: dateTime.hour + 12 }));
            }
          }}
          variant={am ? `unselected` : `selected`}
        >
          PM
        </Button>
      </ButtonGroup>
    </HStack>
  );
}

const DatePickerInput = forwardRef((values, ref) => (
  <InputGroup
    onClick={(values as any).onClick} // eslint-disable-line
    ref={ref as LegacyRef<HTMLDivElement>}
  >
    <Input value={(values as any).value} /> {/* eslint-disable-line */}
    <InputRightElement>
      <CalendarBlank size={22} fill="#2D3748" />
    </InputRightElement>
  </InputGroup>
));

function DatePicker({
  dateTime,
  setDateTime,
  minDate,
  maxDate,
}: {
  dateTime: DateTime;
  setDateTime: (dateTime: DateTime) => void;
  minDate?: DateTime;
  maxDate?: DateTime;
}) {
  return (
    <Box py={2}>
      {dateTime && (
        <ReactDatePicker
          selected={dateTime.toJSDate()}
          onChange={(date) => date && setDateTime(DateTime.fromJSDate(date))}
          showPopperArrow={false}
          wrapperClassName={styles.reactDatePickerInputWrapper}
          customInput={<DatePickerInput />}
          minDate={minDate?.toJSDate()}
          maxDate={maxDate?.toJSDate()}
          calendarClassName={styles.reactDatePickerCalendar}
          popperPlacement="bottom-start"
        />
      )}
    </Box>
  );
}

export function DateTimePicker({
  dateTime,
  setDateTime,
  datePickerLabel,
  timePickerLabel,
  minDate,
  maxDate,
  error,
}: {
  dateTime: DateTime;
  setDateTime: (dateTime: DateTime) => void;
  datePickerLabel: string;
  timePickerLabel: string;
  minDate?: DateTime;
  maxDate?: DateTime;
  error?: { message: string };
}) {
  return (
    <FormControl isInvalid={!!error}>
      <VStack w="full" align="start" spacing="2">
        <FormLabel variant="input" w="full">
          {datePickerLabel}
          <DatePicker
            dateTime={dateTime}
            setDateTime={setDateTime}
            minDate={minDate}
            maxDate={maxDate}
          />
        </FormLabel>
        <FormLabel variant="input">
          {timePickerLabel}
          <TimePicker dateTime={dateTime} setDateTime={setDateTime} />
        </FormLabel>
      </VStack>
      <FormErrorMessage>{error && error.message}</FormErrorMessage>
    </FormControl>
  );
}

export function RowDateTimePicker({
  dateTime,
  setDateTime,
  datePickerLabel,
  timePickerLabel,
  minDate,
  maxDate,
  error,
}: {
  dateTime: DateTime;
  setDateTime: (dateTime: DateTime) => void;
  datePickerLabel: string;
  timePickerLabel: string;
  minDate?: DateTime;
  maxDate?: DateTime;
  error?: { message: string };
}) {
  return (
    <FormControl isInvalid={!!error}>
      <HStack w="full" alignItems="flex-start" justifyContent="flex-start">
        <VStack
          alignItems="flex-start"
          justifyContent="flex-start"
          width="40%"
          gap={0}
        >
          <FormLabel variant="input">{datePickerLabel}</FormLabel>
          <DatePicker
            dateTime={dateTime}
            setDateTime={setDateTime}
            minDate={minDate}
            maxDate={maxDate}
          />
        </VStack>
        <VStack
          alignItems="flex-start"
          justifyContent="flex-start"
          width="60%"
          gap={0}
        >
          <FormLabel variant="input">{timePickerLabel}</FormLabel>
          <TimePicker
            dateTime={dateTime}
            setDateTime={setDateTime}
            buttonGroupSize="sm"
          />
        </VStack>
      </HStack>
      <FormErrorMessage>{error && error.message}</FormErrorMessage>
    </FormControl>
  );
}
