import { ReactNode } from "react";

import { VStack } from "@chakra-ui/react";

import { Skeleton } from "@/modules/Skeleton";

function ComboboxMenuSkeletonWrapper({
  children,
}: {
  readonly children: ReactNode;
}) {
  return (
    <VStack
      justifyContent="center"
      px={5}
      py={3.5}
      borderBottomWidth={1}
      borderColor="white"
      _last={{ borderBottomWidth: 0 }}
    >
      {children}
    </VStack>
  );
}

export default function ComboboxMenuSkeleton() {
  return (
    <VStack alignItems="start" spacing={0}>
      <ComboboxMenuSkeletonWrapper>
        <Skeleton h="12px" w="60px" />
      </ComboboxMenuSkeletonWrapper>
      <ComboboxMenuSkeletonWrapper>
        <Skeleton h="12px" w="120px" />
      </ComboboxMenuSkeletonWrapper>
      <ComboboxMenuSkeletonWrapper>
        <Skeleton h="12px" w="90px" />
      </ComboboxMenuSkeletonWrapper>
      <ComboboxMenuSkeletonWrapper>
        <Skeleton h="12px" w="120px" />
      </ComboboxMenuSkeletonWrapper>
      <ComboboxMenuSkeletonWrapper>
        <Skeleton h="12px" w="150px" />
      </ComboboxMenuSkeletonWrapper>
    </VStack>
  );
}
