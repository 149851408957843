import { useAuthUserOrNull } from "@frontegg/nextjs";
import * as Sentry from "@sentry/nextjs";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

interface VerifyMfaParams {
  readonly token: string;
}

interface VerifyMfaResponse {
  readonly errors: string[];
  readonly recoveryCode?: string;
}

interface OperationState {
  readonly loading: boolean;
  readonly error: unknown;
  readonly data: VerifyMfaResponse | null;
}

const API_URL = `${process.env.NEXT_PUBLIC_FRONTEGG_BASE_URL}/identity/resources/users/v1/mfa/authenticator/enroll/verify`;

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const useVerifyAppMfa = () => {
  const { t } = useTranslation();
  const user = useAuthUserOrNull();
  const [state, setState] = useState<OperationState>(initialState);
  const genericErrorMessage = t(`incorrect_code`);

  const verifyMfa = useCallback(
    async (body: VerifyMfaParams) => {
      setState({ data: null, loading: true, error: null });

      try {
        if (!user) {
          throw new Error(t(`invalid_request`));
        }

        const response = await fetch(API_URL, {
          method: `POST`,
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
          body: JSON.stringify(body),
        });

        const data: VerifyMfaResponse = await response.json();

        if (!response.ok) {
          if (data.errors.length > 0) {
            const [message] = data.errors;
            const errorMessage = message || genericErrorMessage;
            throw new Error(errorMessage);
          }
        }

        setState({ data, loading: false, error: null });
        return { data, error: null, ok: true };
      } catch (error) {
        Sentry.captureException(error);
        setState({ data: null, loading: false, error });
        return { data: null, error, ok: false };
      }
    },
    [genericErrorMessage, t, user],
  );

  return [verifyMfa, state] as const;
};

export default useVerifyAppMfa;
