import * as yup from "yup";
import { FieldError, Merge } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TranslationKey } from "@/i18n/locales";
import Select from "./Select";

type TerminationReasonsSelectProps = {
  readonly name: string;
  readonly label: string;
  readonly placeholder?: TranslationKey;
  readonly helperText?: TranslationKey;
  readonly value: string[];
  readonly options: string[];
  readonly onChange: (vals: string[]) => void;
  readonly error?:
    | yup.ValidationError
    | Merge<FieldError, (FieldError | undefined)[]>;
};

export default function TerminationReasonsSelect({
  name,
  label,
  placeholder = `select_reason`,
  helperText = `internal_use`,
  value,
  options,
  onChange,
  error,
}: TerminationReasonsSelectProps) {
  const { t } = useTranslation();

  const onAddValue = (option: string) => {
    const updatedReasons = [...new Set([...value, option])];
    onChange(updatedReasons);
  };

  const onRemoveValue = (option: string) => {
    const updatedReasons = value.filter((reason) => reason !== option);
    onChange(updatedReasons);
  };

  const onCheckableItemClick = (option: string) => {
    if (value.includes(option)) onRemoveValue(option);
    else onAddValue(option);
  };

  return (
    <Select.Wrapper
      name={name}
      helperText={t(helperText)}
      label={label}
      error={error}
    >
      <Select.Container closeOnSelect={false}>
        <Select.Button isInvalid={!!error} prompt={t(placeholder)} />
        <Select.ItemsContainer>
          {options.map((o) => (
            <Select.CheckableItem
              key={o}
              value={o}
              isChecked={value.includes(o)}
              displayValue={o}
              onClick={onCheckableItemClick}
            />
          ))}
        </Select.ItemsContainer>
      </Select.Container>
      {value && value.length > 0 && (
        <Select.TagContainer mt={4}>
          {value.map((v) => (
            <Select.Tag
              key={v}
              value={v}
              displayValue={v}
              onClick={onRemoveValue}
            />
          ))}
        </Select.TagContainer>
      )}
    </Select.Wrapper>
  );
}
