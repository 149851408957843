import { ReactNode } from "react";

import { FormLabel, FormLabelProps } from "@chakra-ui/react";

import { UseComboboxGetLabelProps } from "@/modules/Form";

interface ComboboxLabelProps extends FormLabelProps {
  readonly children: ReactNode;
  readonly getLabelProps: UseComboboxGetLabelProps;
}

export default function ComboboxLabel({
  children,
  getLabelProps,
  ...formLabelProps
}: ComboboxLabelProps) {
  return (
    <FormLabel variant="input" {...formLabelProps} {...getLabelProps()}>
      {children}
    </FormLabel>
  );
}
