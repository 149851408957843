import { forwardRef, LegacyRef } from "react";

import { Icon, IconProps } from "@chakra-ui/react";

const HiiveLogo = forwardRef(
  (props: IconProps, ref: LegacyRef<SVGSVGElement>) => (
    <Icon
      ref={ref}
      width={21}
      height={21}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="21"
        viewBox="0 0 18 21"
        fill="none"
      >
        <path
          d="M9.20204 0.5L0.5 5.48113V8.5L9.20204 3.51887L17.8041 8.5V5.48113L9.20204 0.5Z"
          fill="white"
        />
        <path
          d="M0.5 11.1001L3.10061 9.6001V13.9501L9.15203 17.5001L15.2535 13.9501V9.6001L17.7541 11.1001V15.4501L9.15203 20.5001L0.5 15.4501V11.1001Z"
          fill="white"
        />
      </svg>
    </Icon>
  ),
);

export default HiiveLogo;
