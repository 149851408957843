// In order for the Chakra CLI to function as expectedly, we must only have one default export.
// Make sure NOT to export any other functions from this file

import { ThemeOverride, extendTheme } from "@chakra-ui/react";
import merge from "lodash/merge";
// eslint-disable-next-line no-restricted-imports
import components from "../components";

import {
  colors,
  fonts,
  textStyles,
  borders,
  shadows,
  radii,
  breakpoints,
  sizes,
  semanticTokens,
  config,
  global,
  fontSizes,
} from ".";

export const extendedTheme = {
  config,
  colors,
  fonts,
  fontSizes,
  textStyles,
  styles: { global },
  borders,
  shadows,
  radii,
  components,
  breakpoints,
  sizes,
  semanticTokens,
};

const theme: ThemeOverride = extendTheme((prevTheme) => {
  const processedTheme: ThemeOverride = merge(prevTheme, extendedTheme);

  return processedTheme;
});

export default theme;
