import { useRouter } from "next/router";
import { useCallback, useState } from "react";
import { FieldValues, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface useConfirmFormCancelProps {
  message?: string;
  onAbort?: () => void;
  onConfirm?: () => void;
}

export function useConfirmFormCancel<T extends FieldValues>({
  message,
  onAbort,
  onConfirm,
}: useConfirmFormCancelProps = {}) {
  const [isRoutingBack, setIsRoutingBack] = useState(false);
  const { formState } = useFormContext<T>();
  const { t } = useTranslation();
  const { back } = useRouter();

  const isFormTouched = Object.keys(formState.touchedFields).length > 0;

  const confirmCancel = useCallback(async () => {
    // eslint-disable-next-line no-alert
    if (!isFormTouched || window.confirm(message || t(`changes_not_saved`))) {
      setIsRoutingBack(true);
      onConfirm?.();
      back();
    } else {
      onAbort?.();
    }
  }, [back, isFormTouched, message, onAbort, onConfirm, t]);

  return { confirmCancel, isRoutingBack };
}
