import { tableAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tableAnatomy.keys);

const baseStyle = definePartsStyle({
  thead: {
    tr: {
      borderColor: `grey.100 !important`,
      borderY: `1px`,
      th: {
        borderColor: `grey.100`,
        bg: `grey.25`,
      },
    },
  },
  tbody: {
    tr: {
      border: `none`,
      td: {
        border: `none`,
      },
    },
  },
});

const Table = defineMultiStyleConfig({ baseStyle });

export default Table;
