import { ErrorBanner, ErrorContainer } from "@/modules/Error";
import { useTranslation } from "react-i18next";

function NotFound() {
  const { t } = useTranslation();

  return (
    <ErrorContainer>
      <ErrorBanner
        title={t(`page_not_found`)}
        description={t(`sorry_requested_page_not_found`)}
      />
    </ErrorContainer>
  );
}

export default NotFound;
