import { MagnifyingGlass } from "@phosphor-icons/react";

import { useColors } from "@/modules/Theme";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { ChangeEventHandler, ForwardedRef, forwardRef } from "react";
import { ChangeHandler } from "react-hook-form";
import GenericInput from "./GenericInput";
import { InputProps } from "./Input";

type InputChangeEventHandler = ChangeEventHandler<
  HTMLInputElement | HTMLTextAreaElement
> &
  ChangeHandler;

interface SearchInputProps extends InputProps {}

const SearchInput = forwardRef<typeof Input, SearchInputProps>(
  (
    { type: _, label, name, onChange, ...inputProps }: SearchInputProps,
    ref: ForwardedRef<typeof Input>,
  ) => {
    const [grey200] = useColors([`grey.200`]);

    const handleChange: InputChangeEventHandler = async ({
      target: { value },
    }) => {
      onChange({
        target: {
          name,
          value: value.trim(),
        },
      });
    };

    return (
      <InputGroup w="full">
        <InputLeftElement mt={label ? `27px` : 0}>
          <MagnifyingGlass size={20} color={grey200} />
        </InputLeftElement>
        <GenericInput
          name={name}
          label={label}
          pl={10}
          {...inputProps}
          ref={ref}
          onChange={handleChange}
        />
      </InputGroup>
    );
  },
);

export default SearchInput;
