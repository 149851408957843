import { Link, HStack, Text } from "@chakra-ui/react";
import { ArrowLeft } from "@phosphor-icons/react";
import { useRouter } from "next/router";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function BackButton({ url }: { url?: string }) {
  const router = useRouter();
  const { t } = useTranslation();

  const [isRoutingToPreviousRoute, setIsRoutingToPreviousRoute] =
    useState(false);
  // keeping the state and disabling back instead of replacing, for the sake of preserving any potential filters applied

  const handleBackClick = async () => {
    if (isRoutingToPreviousRoute) return;

    setIsRoutingToPreviousRoute(true);

    if (url) {
      await router.replace(url);
      return;
    }

    await router.back();
  };

  return (
    <HStack
      as={Link}
      alignItems="center"
      onClick={handleBackClick}
      cursor={isRoutingToPreviousRoute ? `not-allowed` : `pointer`}
      pointerEvents={isRoutingToPreviousRoute ? `none` : `auto`}
    >
      <ArrowLeft size={20} />
      <Text textStyle="colfax-16-medium">{t(`back`)}</Text>
    </HStack>
  );
}
