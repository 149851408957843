import {
  FormControl,
  Checkbox as ChakraCheckbox,
  FormErrorMessage,
  HStack,
  CheckboxProps as ChakraCheckboxProps,
  FormLabel,
} from "@chakra-ui/react";
import { ForwardedRef, ReactNode, forwardRef } from "react";
import { FieldError, UseFormRegisterReturn } from "react-hook-form";

type Props = ChakraCheckboxProps & UseFormRegisterReturn;

interface CheckboxProps extends Props {
  label: string | ReactNode;
  error?: FieldError | boolean;
}

const Checkbox = forwardRef<typeof ChakraCheckbox, CheckboxProps>(
  (
    { label, name, error = false, ...checkboxProps }: CheckboxProps,
    ref: ForwardedRef<typeof ChakraCheckbox>,
  ) => (
    <FormControl isInvalid={!!error} width="auto">
      <HStack spacing={3}>
        <ChakraCheckbox
          size="lg"
          isInvalid={!!error}
          id={name}
          name={name}
          {...checkboxProps}
          ref={ref}
        >
          <FormLabel htmlFor={name} marginBottom="0">
            {label}
          </FormLabel>
        </ChakraCheckbox>
      </HStack>
      {typeof error === `object` && `message` in error && (
        <FormErrorMessage>{error?.message}</FormErrorMessage>
      )}
    </FormControl>
  ),
);

export default Checkbox;
