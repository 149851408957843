import { ReactNode, useEffect, useState } from "react";
import * as yup from "yup";
import { FieldError, Merge } from "react-hook-form";

import {
  Flex,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  FormLabelProps,
} from "@chakra-ui/react";

export enum FormLabelPosition {
  row = `row`,
  column = `column`,
}

export interface InputWrapperProps {
  name: string;
  label?: string;
  helperText?: string;
  error?: Merge<FieldError, (FieldError | undefined)[]> | yup.ValidationError;
  formControlProps?: FormControlProps;
  formLabelProps?: FormLabelProps;
  formLabelPosition?: FormLabelPosition;
}

const helperTextStyle = {
  [FormLabelPosition.row]: {},
  [FormLabelPosition.column]: {
    marginBottom: `0.375rem`,
  },
};

function InputWrapper({
  name,
  label,
  helperText,
  error,
  formControlProps,
  formLabelProps,
  formLabelPosition = FormLabelPosition.row,
  w,
  children,
}: InputWrapperProps & {
  w?: FormControlProps["w"];
  children: ReactNode;
}) {
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    if (helperText?.includes(`*`)) {
      setShowAnimation(true);
    }
  }, [helperText]);

  useEffect(() => {
    const timeout = setTimeout(() => setShowAnimation(false), 1000);

    return () => clearTimeout(timeout);
  }, [showAnimation]);

  return (
    <FormControl w={w ?? `full`} isInvalid={!!error} {...formControlProps}>
      <Flex flexDirection={formLabelPosition} justifyContent="space-between">
        {label && (
          <FormLabel variant="input" htmlFor={name} {...formLabelProps}>
            {label}
          </FormLabel>
        )}
        {helperText && (
          <FormHelperText
            mt={0}
            fontSize={12}
            fontWeight={400}
            color={showAnimation ? `archived` : `grey.600`}
            transition="color .5s ease"
            {...helperTextStyle[formLabelPosition]}
          >
            {helperText}
          </FormHelperText>
        )}
      </Flex>
      {children}
      <FormErrorMessage>{error && error.message}</FormErrorMessage>
    </FormControl>
  );
}

export default InputWrapper;
